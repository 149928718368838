/* eslint-disable react/no-danger */
/**
 * Main application knowledge
 */
import "./style.css";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip
} from "@mui/material";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import i18n from "i18n";
import { display } from "redux-react/reducers/snackBarReducer";
import ChartsActions from "redux-react/actions/chartsActions";
import FaiqActions from "redux-react/actions/faiqActions";
import { useSearchParams } from "react-router-dom";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import MDBadge from "components/Basics/MDBadge";
import { t } from "i18next";
import TestingQuestionDialog from "components/Custom/TestingQuestionDialog/TestingQuestionDialog";

const Message = ({ message }) => {
	let styleDirection = {};

	let { direction, text } = message;

	if (direction === "in") {
		styleDirection = {
			left: 10,
			maxWidth: "80%",
			marginRight: "20%",
			backgroundColor: "white",
			borderRadius: "15px 15px 15px 0",
			padding: 10
		};
	} else {
		styleDirection = {
			right: 10,
			maxWidth: "80%",
			marginLeft: "20%",
			backgroundColor: "#2196f3",
			color: "white",
			fontWeight: "bold",
			borderRadius: "15px 15px 0 15px",
			padding: 10
		};
	}

	return (
		<MDBox mt={1} style={styleDirection}>
			<p
				style={{
					fontSize: 12
					// whiteSpace: "pre-wrap"
				}}
				dangerouslySetInnerHTML={{ __html: text }}
			></p>
		</MDBox>
	);
};

const ConversationBox = ({ conversation }) => {
	const dispatch = useDispatch();
	const { profile } = useSelector(state => state);

	const [questionDetails, setQuestionDetails] = useState(null);

	const [openKnowledgesDialog, setOpenKnowledgesDialog] = useState(false);

	useEffect(() => {
		setQuestionDetails(null);
	}, [conversation]);

	return (
		<MDBox
			style={{
				height: "95%"
			}}
			display="flex"
			flexDirection="column"
		>
			<MDBox
				p={1}
				flex="1"
				borderRadius="md"
				style={{
					position: "relative",
					backgroundColor: "rgb(236 236 236)",
					overflowY: "auto",
					height: "100%"
				}}
			>
				{conversation.messages.map((message, index) => (
					<MDBox
						key={index}
						className="exchangeMessageThread"
						onClick={() => {
							dispatch(
								FaiqActions.getQuestionDetailsByQuestionId(
									profile.assistantID,
									message.questionID,
									res => {
										setQuestionDetails(res);
									}
								)
							);
						}}
					>
						<Tooltip placement="left" title={t("HISTORY.ACTIONS.seeMoreAboutExchange")}>
							<Message message={{ text: message.question, direction: "out" }} />
							<Message message={{ text: message.reply, direction: "in" }} />
						</Tooltip>
					</MDBox>
				))}
			</MDBox>
			{questionDetails && (
				<MDBox flex="1" mt={1}>
					{/* Header */}
					<MDBox
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<MDTypography variant="h6">{t("HISTORY.ACTIONS.details")}</MDTypography>
						<IconButton onClick={() => setQuestionDetails(null)}>
							<Icon>close</Icon>
						</IconButton>
					</MDBox>
					{/* Content */}
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>
									<MDTypography
										variant="caption"
										fontWeight="bold"
										style={{ whiteSpace: "nowrap" }}
									>
										{t("HISTORY.ACTIONS.formattedQuestion")}
									</MDTypography>
								</TableCell>
								<TableCell>
									<MDTypography variant="caption">{questionDetails.formattedQuestion}</MDTypography>
								</TableCell>
							</TableRow>
							{/*  */}
							<TableRow>
								<TableCell>
									<MDTypography variant="caption" fontWeight="bold">
										{t("HISTORY.ACTIONS.detectedLangage")}
									</MDTypography>
								</TableCell>
								<TableCell>
									<MDTypography variant="caption">{questionDetails.questionLanguage}</MDTypography>
								</TableCell>
							</TableRow>
							{/*  */}
							<TableRow>
								<TableCell>
									<MDTypography variant="caption" fontWeight="bold">
										{t("HISTORY.ACTIONS.replyDuration")}
									</MDTypography>
								</TableCell>
								<TableCell>
									<MDTypography variant="caption">{`${questionDetails.replyDuration.toFixed(
										1
									)} secondes`}</MDTypography>
								</TableCell>
							</TableRow>
							{/*  */}
							<TableRow>
								<TableCell>
									<MDTypography variant="caption" fontWeight="bold">
										{t("HISTORY.ACTIONS.emptyContext")}
									</MDTypography>
								</TableCell>
								<TableCell>
									<MDTypography variant="caption">
										{questionDetails.isEmptyContextReply ? t("SETTINGS.yes") : t("SETTINGS.no")}
									</MDTypography>
								</TableCell>
							</TableRow>
							{/*  */}
							<TableRow>
								<TableCell>
									<MDTypography variant="caption" fontWeight="bold">
										{t("HISTORY.ACTIONS.noInformations")}
									</MDTypography>
								</TableCell>
								<TableCell>
									<MDTypography variant="caption">
										{questionDetails.isIDontKnowReply ? t("SETTINGS.yes") : t("SETTINGS.no")}
									</MDTypography>
								</TableCell>
							</TableRow>
							{/*  */}
							<TableRow>
								<TableCell>
									<MDTypography variant="caption" fontWeight="bold">
										{t("HISTORY.ACTIONS.foundedKnowledges")}
									</MDTypography>
								</TableCell>
								<TableCell>
									<MDBox display="flex" alignItems="center">
										<MDTypography variant="caption">
											{questionDetails.knowledges.length}
										</MDTypography>
										<MDButton
											variant="text"
											color="info"
											onClick={() => setOpenKnowledgesDialog(true)}
										>
											{t("SETTINGS.see")}
										</MDButton>
									</MDBox>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</MDBox>
			)}
			{questionDetails && (
				<Dialog
					open={openKnowledgesDialog}
					onClose={() => setOpenKnowledgesDialog(false)}
					fullWidth
					maxWidth="xxl"
					PaperProps={{
						sx: {
							height: "90%"
						}
					}}
				>
					<DialogTitle id="alert-dialog-title">{t("FAIQ.TESTING.foundedKnowledges")}</DialogTitle>
					<DialogContent>
						<Table>
							<MDBox component="tr" mb={1.5}>
								<MDTypography variant="h6" component="th" align="left">
									UUID
								</MDTypography>
								<MDTypography variant="h6" component="th" align="left">
									Content
								</MDTypography>
								<MDTypography variant="h6" component="th" align="left">
									Metadatas
								</MDTypography>
							</MDBox>

							<TableBody>
								{questionDetails.knowledges.map((knowledge, index) => {
									if (lod_.isNil(knowledge)) {
										return null;
									}

									if (knowledge.exists === false) {
										return (
											<TableRow key={index}>
												<TableCell>
													<MDTypography variant="body2" fontSize="small">
														{knowledge.uuid}
													</MDTypography>
												</TableCell>
												<TableCell>
													<MDBadge
														badgeContent={t("HISTORY.ACTIONS.deletedKnowledge")}
														container
														size="md"
														color="error"
													/>
												</TableCell>
												<TableCell></TableCell>
											</TableRow>
										);
									}

									return (
										<TableRow key={index}>
											<TableCell>
												<MDTypography variant="body2" fontSize="small">
													{knowledge.uuid}
												</MDTypography>
											</TableCell>
											<TableCell>
												<MDTypography variant="body2" fontSize="small">
													{knowledge.content}
												</MDTypography>
											</TableCell>
											<TableCell>
												<Table>
													{knowledge.metadatas &&
														Object.keys(knowledge.metadatas).map((key, index) => {
															let value = knowledge.metadatas[key];
															return (
																<TableRow key={index}>
																	<td
																		style={{
																			fontWeight: "bold"
																		}}
																	>
																		{key}
																	</td>
																	<td>{value}</td>
																</TableRow>
															);
														})}
												</Table>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</DialogContent>
					<DialogActions>
						<MDButton
							variant="contained"
							color="info"
							onClick={() => setOpenKnowledgesDialog(false)}
						>
							{t("SETTINGS.close")}
						</MDButton>
					</DialogActions>
				</Dialog>
			)}
		</MDBox>
	);
};

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function HistoryPage({ route }) {
	const [searchParams] = useSearchParams();
	// Possible search params in URL
	const knowledge = searchParams.get("knowledge");

	const { profile, filters } = useSelector(state => state);
	const permissions = useSelector(state => state.permissions?.permissions);
	const dispatch = useDispatch();
	// Charts states
	const [rawCharts, setRawCharts] = useState({});
	const [pageFilters, setPageFilters] = useState([]);
	const [paginedList, setPaginedList] = useState(null);
	// Load charts
	const [reloadTable, setReloadTable] = useState(false);

	const rightPannelContainer = useRef(null);
	const [openPannel, setOpenPannel] = useState(false);
	const [currentConversation, setCurrentConversation] = useState(null);

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});

	const [testingQuestionDialog, setTestingQuestionDialog] = useState({
		open: false
	});

	const closePannel = () => {
		setOpenPannel(false);
		setCurrentConversation(null);
	};

	function loadPaginedList() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts({ ...rawCharts, [route.route]: res.charts });
		};

		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));
	}
	// Build charts
	function buildRawCharts() {
		// Success handler : set charts data
		const onSuccess = res => {
			setPaginedList(res.charts[0]);
			setReloadTable(!reloadTable);
		};
		// Build charts
		if (!rawCharts[route.route]) return;
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);

		dispatch(
			ChartsActions.buildRawCharts(
				rawCharts[route.route],
				actualFilters,
				mandatoryFilters,
				onSuccess
			)
		);
	}
	// Pin / Unpin question
	function pinnedHistory(questionID, pinned) {
		const onSuccess = res => {
			dispatch(
				display({
					type: "success",
					message: pinned
						? i18n.t("HISTORY.PINNED.pinStatus")
						: i18n.t("HISTORY.PINNED.unpinStatus")
				})
			);
			setReloadTable(!reloadTable);
		};

		dispatch(
			FaiqActions.pinnedHistory(
				profile.assistantID,
				{
					questionID,
					pinned
				},
				onSuccess
			)
		);
	}

	function getParamsFilter() {
		let params = {};
		if (knowledge) {
			params["knowledgeIds.uuid"] = knowledge;
		}
		return params;
	}

	const openConversationPannel = values => {
		dispatch(
			FaiqActions.getConversationByThreadId(profile.assistantID, values.threadID, res => {
				setCurrentConversation({ threadID: values.threadID, messages: res });
				setOpenPannel(true);
			})
		);
	};

	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	return (
		<DashboardLayout>
			<MDBox>
				<DashboardNavbar
					filters={[
						<MDBox display="flex">
							{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
						</MDBox>
					]}
				/>
			</MDBox>

			{paginedList && (
				<MDBox
					className="pageContentContainer"
					style={{
						display: openPannel ? "flex" : "block"
					}}
				>
					<MDBox
						flex="1"
						style={{
							overflowX: "auto"
						}}
					>
						<DefaultDataTable
							route={route}
							onRowClick={values => {
								openConversationPannel(values);
							}}
							isSelectedRow={values => {
								if (!currentConversation) {
									return false;
								}

								return values.threadID === currentConversation?.threadID;
							}}
							form={route.form}
							dictionary={paginedList.dictionary}
							canSearch
							table={paginedList.data}
							display={paginedList.request.attributesDisplay}
							pagination={paginedList.pagination}
							list={paginedList}
							actions={[
								<IconButton
									handleclick={({ values }, e) => {
										setMenuValues(values);
										setMenuRef(e.currentTarget);
										e.preventDefault();
										e.stopPropagation();
									}}
								>
									<Icon fontSize="medium">more_vert</Icon>
								</IconButton>
							]}
							reloadTable={reloadTable}
							filters={{
								...getLocalStorageBackValues(
									profile.selectedAssistant.assistantID,
									route.route,
									filters
								),
								...getParamsFilter()
							}}
						/>
					</MDBox>
					{openPannel && (
						<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
							<MDBox
								className="rightPannel"
								sx={{ ml: 2 }}
								style={{
									maxWidth: "30vw"
								}}
							>
								<Card sx={{ p: 2, height: "100%" }}>
									<MDBox display="flex" alignItems="center" justifyContent="space-between">
										<MDBox>{t("HISTORY.CONVERSATION.conversation")}</MDBox>
										<MDBox>
											<Tooltip placement="top" title={t("SETTINGS.close")}>
												<IconButton onClick={() => closePannel()}>
													<Icon>close</Icon>
												</IconButton>
											</Tooltip>
										</MDBox>
									</MDBox>
									{/* Conversation */}
									{currentConversation && <ConversationBox conversation={currentConversation} />}
								</Card>
							</MDBox>
						</MDBox>
					)}
				</MDBox>
			)}

			{/* Menu actions selection */}
			<Menu
				open={Boolean(menuRef)}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
				}}
			>
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.actions`)}</MDTypography>
				</MenuItem>
				{/* Favorite pin */}
				<MenuItem
					style={menuValues.pinned ? { color: "#ffcd00" } : {}}
					onClick={() => {
						let pinned = menuValues.pinned ?? false;
						pinnedHistory(menuValues.questionID, !pinned);
						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">star</Icon>&nbsp;{t("HISTORY.favorite")}
				</MenuItem>
				{/* Open conversation */}
				<MenuItem
					onClick={() => {
						openConversationPannel(menuValues);
						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">message</Icon>&nbsp;
					{t("HISTORY.CONVERSATION.displayConversation")}
				</MenuItem>
				{/* Create testing question */}
				{permissions.includes("exec:overtrainingFromExchange") && (
					<MenuItem
						onClick={() => {
							// openConversationPannel(menuValues);
							setTestingQuestionDialog({
								open: true,
								questionID: menuValues.questionID,
								threadID: menuValues.threadID
							});
							setMenuRef(null);
						}}
					>
						<Icon fontSize="medium">mode_standby</Icon>&nbsp;{t("HISTORY.ACTIONS.overtraining")}
					</MenuItem>
				)}
			</Menu>

			{/* Testing question dialog */}
			<TestingQuestionDialog
				{...testingQuestionDialog}
				open={testingQuestionDialog.open}
				onClose={() => setTestingQuestionDialog({ open: false })}
			/>

			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
