/* eslint-disable react/no-danger */
/* eslint-disable import/prefer-default-export */
import "./style.css";
import lod_ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
	Modal,
	Fab,
	Fade,
	IconButton,
	Avatar,
	Icon,
	Tooltip,
	Menu,
	InputAdornment
} from "@mui/material";
import { ChatBubble, Close } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import MDTypography from "components/Basics/MDTypography";
import MDBox from "components/Basics/MDBox";
import { useDispatch, useSelector } from "react-redux";
import FAIQActions from "redux-react/actions/faiqActions";
import MDInput from "components/Basics/MDInput";
import { CHAT_MODAL_MODES } from "constants";
import { getComponentsInfos } from "helpers/chat";

const AddMetadataFilter = ({ addFilter }) => {
	const [key, setKey] = useState("");
	const [value, setValue] = useState("");

	const add = () => {
		if (key && value) {
			addFilter(key, value);
			setKey("");
			setValue("");
		}
	};

	return (
		<MDBox>
			<MDInput placeholder="Clé" value={key} onChange={e => setKey(e.target.value)}></MDInput>
			<MDInput
				placeholder="Valeur"
				value={value}
				onChange={e => setValue(e.target.value)}
			></MDInput>
			<IconButton onClick={add} disabled={!key || !value}>
				<Icon>add</Icon>
			</IconButton>
		</MDBox>
	);
};

const SourceItem = ({ source, displayedSource, sourcesLength, nextSource, previousSource }) => {
	return (
		<MDBox mt={3}>
			<MDBox display="flex" justifyContent="space-between" alignItems="center">
				{sourcesLength > 1 && (
					<IconButton onClick={() => previousSource()}>
						<Icon fontSize="small">arrow_back</Icon>
					</IconButton>
				)}
				<MDBox flex="1" display="flex" alignItems="center" justifyContent="center">
					<MDTypography variant="h6" fontSize="small">
						{displayedSource + 1}/{sourcesLength}
					</MDTypography>
				</MDBox>
				{sourcesLength > 1 && (
					<IconButton onClick={() => nextSource()}>
						<Icon fontSize="small">arrow_forward</Icon>
					</IconButton>
				)}
			</MDBox>
			<MDBox
				component="a"
				href={source.url}
				target="_blank"
				rel="noreferrer"
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="flex-start"
				mt={1}
				borderRadius="md"
				p={1}
				style={{
					cursor: "pointer"
				}}
				bgColor="light"
				shadow="md"
			>
				<MDBox mr={1}>
					<Avatar
						alt={source.title.toUpperCase()}
						src={source.image}
						sx={{ width: 32, height: 32 }}
						imgProps={{
							style: {
								border: 0,
								objectFit: "cover",
								height: "100%"
							}
						}}
					></Avatar>
				</MDBox>
				<MDBox
					style={{
						overflow: "hidden"
					}}
				>
					<MDTypography variant="h6" fontSize="small" className="sourceChatTitle">
						{source.title}
					</MDTypography>
					<MDTypography
						variant="body2"
						className="sourceChatDescription"
						style={{
							fontSize: 12
						}}
					>
						{source.description}
					</MDTypography>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

/**
 * Message component
 * @returns
 */
const Message = ({
	message,
	sources = [],
	error = false,
	direction,
	loading = false,
	currentMode
}) => {
	const [displayedSource, setDisplayedSource] = useState(0);

	const nextSource = () => {
		setDisplayedSource(prev => (prev + 1) % sources.length);
	};

	const previousSource = () => {
		let sourcesLength = sources.length;
		let newDisplayedSource = displayedSource - 1;
		if (newDisplayedSource < 0) {
			newDisplayedSource = sourcesLength - 1;
		}
		setDisplayedSource(newDisplayedSource);
	};

	let styleDirection = {};

	if (direction === "in") {
		styleDirection = {
			left: 10,
			maxWidth: "80%",
			marginRight: "20%",
			backgroundColor: error ? "#F44335" : "white",
			color: error ? "white" : "#344767",
			fontWeight: error ? "bold" : "normal",
			borderRadius: "15px 15px 15px 0",
			padding: 10
		};
	} else if (direction === "out") {
		styleDirection = {
			right: 10,
			maxWidth: "80%",
			marginLeft: "20%",
			// backgroundColor: "#2196f3",
			color: "white",
			fontWeight: "bold",
			borderRadius: "15px 15px 0 15px",
			padding: 10
		};
	}

	if (loading) {
		return (
			<MDBox borderRadius="md" className="chat-bubble" mt={1} style={styleDirection} p={1}>
				<div className="typing">
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
				</div>
			</MDBox>
		);
	}

	return (
		<MDBox mt={1} style={styleDirection} bgColor={getComponentsInfos(currentMode).mainColor}>
			<p
				style={{
					fontSize: 12
					// whiteSpace: "pre-wrap"
				}}
				dangerouslySetInnerHTML={{ __html: message }}
			></p>
			{sources.map((source, index) => {
				if (displayedSource === index) {
					return (
						<SourceItem
							key={index}
							source={source}
							displayedSource={displayedSource}
							sourcesLength={sources.length}
							nextSource={nextSource}
							previousSource={previousSource}
						/>
					);
				} else {
					return null;
				}
			})}
		</MDBox>
	);
};

/**
 * Chat component
 */
export const ChatModal = () => {
	// Redux
	let error = useRef(false);
	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);

	// Modal state
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	/**
	 * Multiple states for the chat, we have:
	 * - assistant : Default mode, respond to user with the assistant knowledge
	 * - help : Help mode, respond to user with help knowledges (different API key in backend)
	 */
	const [currentMode, setCurrentMode] = useState(CHAT_MODAL_MODES.ASSISTANT);

	const [threadID, setThreadID] = useState(null);
	// Message state
	const [message, setMessage] = useState("");
	// List of messages
	const [messageList, setMessageList] = useState([]);
	// Configs
	const [botConfig, setBotConfig] = useState(null);

	const [metadatas, setMetadatas] = useState({});
	const [metadatasFilterRef, setMetadatasFilterRef] = useState(null);

	const [chunkBuffer, setChunkBuffer] = useState("");

	const [temporalMessage, setTemporalMessage] = useState("");
	// Scroll to bottom ref
	const messagesEndRef = useRef(null);

	// Get current page path
	const currentPage = window.location.pathname;
	// Disable chat on some pages
	const DISABLED_PAGES = ["/login"];

	const addMedatasFilter = (key, value) => {
		setMetadatas(prev => ({ ...prev, [key]: value }));
	};

	const deleteMetadatasFilter = key => {
		setMetadatas(prev => {
			let newPrev = { ...prev };
			delete newPrev[key];
			return newPrev;
		});
	};

	// Send message function
	const sendMessage = async () => {
		if (!message.trim()) return;
		setTemporalMessage("");
		setChunkBuffer("");

		setMessageList(prevList => [...prevList, { message, direction: "out" }]);
		setMessage("");
		let randomID = uuidv4();

		// setTimeout(() => {
		setMessageList(prevList => [
			...prevList,
			{ message: ". . .", direction: "in", loading: true, id: randomID }
		]);
		// }, 100);

		// Create future message
		let questionID = uuidv4();
		let newMessage = {
			message: temporalMessage,
			direction: "in",
			sources: [],
			id: questionID
		};

		error.current = false;

		dispatch(
			FAIQActions.tryFAIQ(
				profile.assistantID,
				message,
				questionID,
				threadID,
				metadatas,
				{
					mode: currentMode
				},
				// Before stream start
				() => {
					setMessageList(prevList => prevList.filter(item => item.id !== randomID));
					setMessageList(prevList => [...prevList, newMessage]);
				},
				// While reveiving data
				({ chunk }) => {
					setChunkBuffer(prevBuffer => prevBuffer + chunk);
					// Regex to test if we have an end message
					const regexEnd = /^\{"type":"end".*\}$/;
					// Regex to test if we have an error
					const regexError = /^\{"type":"error".*\}$/;

					switch (true) {
						case regexError.test(chunk): {
							error.current = true;
							// We have an end message
							let parsedChunk = JSON.parse(chunk);

							setMessageList(prevList => {
								return [
									...prevList.filter(item => item.id !== randomID && item.id !== questionID),
									{
										message: "Le service est momentanément indisponible",
										error: true,
										direction: "in",
										sources: []
									}
								];
							});

							break;
						}

						case regexEnd.test(chunk): {
							// We have an end message
							let parsedChunk = JSON.parse(chunk);

							setTemporalMessage(prevMessage => {
								setMessageList(prevList => [
									...prevList.filter(item => item.id !== questionID),
									{ ...newMessage, message: prevMessage, sources: parsedChunk?.payload?.sources }
								]);

								return prevMessage;
							});
							break;
						}
						default: {
							// We have a new chunk
							setTemporalMessage(prevMessage => {
								let update = prevMessage + chunk;

								setMessageList(prevList => [
									...prevList.filter(item => item.id !== questionID),
									{ ...newMessage, message: update }
								]);

								return update;
							});
							break;
						}
					}
				},
				// Last event of stream (when close)
				() => {},
				// After stream end
				() => {
					/**
					 * At the end of the stream, get the buffer content and
					 * get the message & the sources from it.
					 * Set it into the current message to avoid chunk looses
					 * (it happends some times)
					 */
					if (error.current) {
						return;
					}

					if (!lod_.isNil(chunkBuffer) && !lod_.isEmpty(chunkBuffer)) {
						let splitBuffer = chunkBuffer.split(/(?=\{"type":"end")/);
						const message = splitBuffer[0];
						const sources = splitBuffer[1];

						let jsonSources = [];

						try {
							jsonSources = JSON.parse(sources)?.payload?.sources;
						} catch {
							// Do nothing
						}

						setMessageList(prevList => [
							...prevList.filter(item => item.id !== questionID),
							{ ...newMessage, message, sources: jsonSources }
						]);
					}
				},
				err => {
					setMessageList(prevList => prevList.filter(item => item.id !== randomID));
					setMessageList(prevList => [
						...prevList,
						{
							message: "Le service est momentanément indisponible",
							error: true,
							direction: "in",
							sources: []
						}
					]);
				}
			)
		);
	};

	// Scroll to bottom
	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const resetChat = () => {
		setMessageList([]);
		setThreadID(uuidv4());
	};

	// Scroll to bottom when message list change
	useEffect(() => {
		scrollToBottom();
	}, [messageList]);

	// Scroll to bottom when component reload
	setTimeout(() => {
		scrollToBottom();
	}, 100);

	useEffect(() => {
		// Set thread ID on component mount
		resetChat();
	}, [profile.assistantID]);

	// Get bot config
	useEffect(() => {
		if (profile.assistantID) {
			dispatch(
				FAIQActions.getFaiqConfig(profile.assistantID, ({ config }) => {
					let botConfig = {
						testBotName: config?.testBotName,
						testBotEntryMessage: config?.testBotEntryMessage
					};
					setBotConfig(botConfig);
				})
			);
		}
	}, [profile.assistantID]);

	return (
		<>
			{!open && botConfig && !DISABLED_PAGES.includes(currentPage) && (
				<Fade in={!open}>
					<div
						style={{
							position: "fixed",
							bottom: 20,
							right: 20,
							zIndex: 1000
						}}
					>
						<Fab variant="extended" color="info" onClick={handleOpen}>
							<ChatBubble sx={{ mr: 1 }} />
							<MDTypography variant="h6" color="light">
								Essayer
							</MDTypography>
						</Fab>
					</div>
				</Fade>
			)}

			{botConfig && (
				<Modal
					open={open}
					onClose={handleClose}
					BackdropProps={{
						invisible: true
					}}
					style={{
						display: "flex",
						alignItems: "flex-end",
						justifyContent: "flex-end",
						overflowY: "auto"
					}}
				>
					<Fade in={open}>
						<MDBox
							borderRadius="xl"
							shadow="xl"
							sx={{
								overflow: "hidden",
								width: "100%",
								maxWidth: 400,
								boxShadow: "0px 0px 24px 5px rgba(0,0,0,0.31)",
								position: "relative",
								zIndex: 50,
								m: 2
							}}
						>
							{/* Header */}
							<MDBox
								p={2}
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="space-between"
								bgColor={getComponentsInfos(currentMode).mainColor}
								variant="gradient"
							>
								<MDBox>
									<MDBox display="flex" flexDirection="row" alignItems="center">
										{currentMode === CHAT_MODAL_MODES.ASSISTANT && (
											<MDBox>
												<Tooltip placement="top" title="Obtenir de l'aide">
													<IconButton
														color={getComponentsInfos(currentMode).secondColor}
														size="large"
														onClick={() => {
															setCurrentMode(CHAT_MODAL_MODES.HELP);
															resetChat();
														}}
													>
														<Icon>help</Icon>
													</IconButton>
												</Tooltip>
											</MDBox>
										)}
										{currentMode === CHAT_MODAL_MODES.HELP && (
											<MDBox>
												<Tooltip placement="top" title="Retour à l'assistant">
													<IconButton
														color={getComponentsInfos(currentMode).secondColor}
														size="large"
														onClick={() => {
															setCurrentMode(CHAT_MODAL_MODES.ASSISTANT);
															resetChat();
														}}
													>
														<Icon>people</Icon>
													</IconButton>
												</Tooltip>
											</MDBox>
										)}
										{currentMode === CHAT_MODAL_MODES.HELP && (
											<MDTypography
												color={getComponentsInfos(currentMode).secondColor}
												variant="h5"
											>
												Aide FAIQ
											</MDTypography>
										)}
										{currentMode === CHAT_MODAL_MODES.ASSISTANT && (
											<MDTypography
												color={getComponentsInfos(currentMode).secondColor}
												variant="h5"
											>
												{botConfig.testBotName}
											</MDTypography>
										)}
									</MDBox>
								</MDBox>
								<MDBox>
									{/* Reset button */}
									<IconButton onClick={resetChat}>
										<Tooltip title="Remettre à zéro" placement="top">
											<Icon fontSize="small">replay</Icon>
										</Tooltip>
									</IconButton>
									{/* Filters button */}
									<IconButton onClick={e => setMetadatasFilterRef(e.target)}>
										<Tooltip title="Ajouter un filtre sur les métadonnées" placement="top">
											<Icon fontSize="small">filter_alt</Icon>
										</Tooltip>
									</IconButton>
									{/* Close button */}
									<Tooltip title="Fermer le chat" placement="top">
										<IconButton onClick={handleClose}>
											<Close fontSize="medium" />
										</IconButton>
									</Tooltip>
									{/* Menu filters metadatas */}
									<Menu
										anchorEl={metadatasFilterRef}
										open={Boolean(metadatasFilterRef)}
										onClose={() => setMetadatasFilterRef(null)}
									>
										<MDBox display="flex" flexDirection="column" alignItems="flex-start">
											{Object.keys(metadatas).map(key => (
												<MDBox
													display="flex"
													flexDirection="row"
													key={key}
													mt={1}
													alignItems="center"
													justifyContent="space-between"
													style={{
														width: "100%"
													}}
												>
													<MDTypography variant="h6">{`${key} : ${metadatas[key]}`}</MDTypography>
													<IconButton onClick={() => deleteMetadatasFilter(key)}>
														<Icon>delete</Icon>
													</IconButton>
												</MDBox>
											))}

											<MDBox>
												<AddMetadataFilter
													addFilter={(key, value) => {
														addMedatasFilter(key, value);
													}}
												/>
											</MDBox>
										</MDBox>
									</Menu>
								</MDBox>
							</MDBox>
							{/* Messages */}
							<MDBox
								p={1}
								style={{
									position: "relative",
									height: "45vh",
									backgroundColor: "rgb(236 236 236)",
									overflowY: "auto"
								}}
								shadow="xs"
							>
								{currentMode === CHAT_MODAL_MODES.HELP && (
									<Message
										currentMode={currentMode}
										direction="in"
										message="Bonjour, je suis l'aide FAIQ ! Un soucis avec l'application ? N'hésitez pas à me poser des questions, je suis là pour vous aider ! 🤗
									"
									/>
								)}

								{currentMode === CHAT_MODAL_MODES.ASSISTANT && (
									<Message
										currentMode={currentMode}
										direction="in"
										message={botConfig.testBotEntryMessage}
									/>
								)}
								{messageList.map((message, index) => {
									return (
										<Message
											key={index}
											currentMode={currentMode}
											direction={message.direction}
											error={message.error}
											sources={message.sources}
											message={message.message}
											loading={message.loading}
										/>
									);
								})}
								<div ref={messagesEndRef}></div>
							</MDBox>
							{/* Input */}
							<MDBox
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="space-between"
								style={{
									overflow: "hidden",
									border: "none"
								}}
								bgColor="white"
								className="chat-input"
							>
								<MDInput
									className="chat-input"
									placeholder="Question ..."
									value={message}
									onChange={e => setMessage(e.target.value)}
									onKeyDown={e => {
										if (e.key === "Enter") {
											sendMessage();
											e.preventDefault();
										}
									}}
									fullWidth
									multiline
									minRows={1}
									size="medium"
									variant="outlined"
									style={{
										padding: 10
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={sendMessage}>
													<Icon>arrow_upward</Icon>
												</IconButton>
											</InputAdornment>
										)
									}}
								></MDInput>
							</MDBox>
						</MDBox>
					</Fade>
				</Modal>
			)}
		</>
	);
};
