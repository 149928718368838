/* eslint-disable no-restricted-syntax */
import { CircularProgress, Grid, Icon } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { SHOPIFY_RESOURCE } from "constants";
import { checkIfSourcesExist } from "hooks/sources";
import { t } from "i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SourceActions from "redux-react/actions/sourceActions";

/**
 * Grid item component
 * @param {*} param0
 * @returns {JSX.Element} - Grid item
 */
const GridItem = ({ icon, text, onClick, ...rest }) => {
	return (
		<Grid
			item
			xs={6}
			sm={4}
			md={3}
			lg={2}
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center"
			}}
			onClick={onClick}
			{...rest}
		>
			<MDBox
				className="integrationBox"
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="space-between"
				borderRadius="lg"
				p={2}
				sx={{
					height: "100%",
					width: "100%"
				}}
				onClick={() => {}}
			>
				<MDBox>
					<Icon fontSize="large">{icon}</Icon>
				</MDBox>
				<MDTypography variant="body1" fontSize="small">
					{text}
				</MDTypography>
			</MDBox>
		</Grid>
	);
};

/**
 * Shopify selection component
 * @param {*} param0
 * @returns  {JSX.Element} ShopifySelection
 */
const ShopifySelection = ({ onFilesSelection, extra }) => {
	const dispatch = useDispatch();

	const [loadingItems, setLoadingItems] = useState(false);

	/**
	 * Convert shopify result into "file" format
	 * @param {object} result - Shopify result
	 * @param {string} domain - Shopify domain
	 * @param {string} resource - Shopify resource
	 */
	const mapResult = async (result, domain, resource) => {
		const files = [];
		for (let item of result) {
			const url = `${domain}${resource}/${item.handle}`;

			let file = {
				url,
				name: item.title,
				extra: {
					apiResult: item,
					rawText: item.body_html
				}
			};
			files.push(file);
		}

		const filesResult = await checkIfSourcesExist(dispatch, files);
		setLoadingItems(false);
		onFilesSelection(filesResult);
	};

	/**
	 * Get shopify items
	 * @param {string} resource - Shopify resource
	 */
	const getShopifyItems = async resource => {
		setLoadingItems(true);
		dispatch(
			SourceActions.getShopifyResourceForChannel(
				resource,
				extra.channelAssistantID,
				extra.code,
				({ result, domain }) => {
					mapResult(result, domain, resource);
				},
				() => setLoadingItems(false)
			)
		);
	};

	return (
		<MDBox mt={5} display="flex" flex="1" flexDirection="row" justifyContent="center">
			<MDBox sx={{ width: "70%" }}>
				<MDTypography variant="h4">{t("SOURCES.SHOPIFY.insertDatasFromShopifyTitle")}</MDTypography>
				<MDTypography variant="subtitle2">
					{t("SOURCES.SHOPIFY.insertDatasFromShopifySubtitle")}
				</MDTypography>
				{loadingItems ? (
					<MDBox display="flex" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
						<CircularProgress color="info" size={50} />
					</MDBox>
				) : (
					<Grid container sx={{ mt: 5 }}>
						<GridItem
							icon="description"
							text={t("SOURCES.SHOPIFY.shopifyPages")}
							onClick={() => getShopifyItems(SHOPIFY_RESOURCE.PAGES)}
						/>
						{/* Hide sopify products for now */}
						<GridItem
							icon="shopping_cart"
							text={t("SOURCES.SHOPIFY.shopifyProducts")}
							onClick={() => getShopifyItems(SHOPIFY_RESOURCE.PRODUCTS)}
						/>
					</Grid>
				)}
			</MDBox>
		</MDBox>
	);
};

export default ShopifySelection;
