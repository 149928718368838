/* eslint-disable prefer-destructuring */
import { useEffect, useState } from "react";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import lod_ from "lodash";
import { LittleForm } from "components/Custom/LittleForm";
import MDInput from "components/Basics/MDInput";
import { createDictionarySkeleton } from "helpers/form";
import { t } from "i18next";

/**
 * Metadatas step, user can
 * add metadatas to the sources
 * @param {*} param0
 * @returns {JSX.Element} - StepMetadatas
 */
export default function StepMetadatas({ skeleton, validStep, onChange, metadatas }) {
	// Fields to disable
	const DISABLED_FIELDS = ["title", "description", "url", "image"];

	/**
	 * Process metadatas
	 * Disable some fields that we dont want to display in this step
	 * @param {object} metadatas - Dictionary of metadatas
	 * @returns {object} - Processed dictionary metadatas
	 */
	const processMetadatas = metadatas => {
		let clone = lod_.cloneDeep(metadatas);
		let items = clone.items;

		Object.entries(items).forEach(([key, value]) => {
			if (DISABLED_FIELDS.includes(key)) {
				items[key].display = false;
			}
		});

		clone.items = items;
		return clone;
	};

	const [metadatasSkeleton, setMetadatasSkeleton] = useState(
		createDictionarySkeleton(processMetadatas(metadatas), skeleton.metadatas)
	);

	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(metadatasSkeleton);
		lod_.set(clone, path, value);
		onChange(clone);
		setMetadatasSkeleton(clone);
	};

	useEffect(() => {
		validStep();
		onChange(metadatasSkeleton);
	}, []);

	return (
		<MDBox mt={5} display="flex" flex="1" flexDirection="row" justifyContent="center">
			<MDBox sx={{ width: "70%" }}>
				<MDBox>
					<MDTypography variant="h5">{t("SOURCES.METADATAS.title")}</MDTypography>
					<MDTypography variant="subtitle2">{t("SOURCES.METADATAS.subtitle")}</MDTypography>
					<MDBox mt={2}>
						<LittleForm
							object={processMetadatas(metadatas)}
							metadatasSkeleton={metadatasSkeleton}
							handleChange={handleChange}
						/>
					</MDBox>
				</MDBox>
				{/* HTML */}
				{["url", "sitemap"].includes(skeleton.importMethod) && (
					<MDBox mt={4}>
						<MDTypography variant="h5">Options site web</MDTypography>
						<MDInput
							sx={{ mt: 2 }}
							fullWidth
							label="HTML Selector"
							value={skeleton.metadatas.htmlSelector}
							onChange={e => {
								handleChange("htmlSelector", e.target.value);
							}}
						/>
					</MDBox>
				)}
			</MDBox>
		</MDBox>
	);
}
