/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/**
 * Main application charts
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Tooltip
} from "@mui/material";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import { getLocalStorageBackValues } from "components/Custom/Filters/filters";
import i18n from "i18n";
import lod_ from "lodash";
import { display } from "redux-react/reducers/snackBarReducer";
import MDButton from "components/Basics/MDButton";
import DictionaryDataTable from "components/Custom/Tables/DictionaryDataTable";
import { LittleForm } from "components/Custom/LittleForm";
import { createDictionarySkeleton } from "helpers/form";

const MetadatasDialog = ({
	open,
	onClose,
	onSave,
	edit = false,
	defaultValues = {},
	item,
	dictionary
}) => {
	const mandatoryValues = {
		active: true,
		type: "string",
		isArray: true,
		analytics: true,
		actions: {
			edition: true
		}
	};

	const [skeleton, setSkeleton] = useState(createDictionarySkeleton(dictionary, item || {}));

	const isDisabled = () => {
		if (lod_.isNil(skeleton.code) || lod_.isEmpty(skeleton.code)) {
			return true;
		}

		const labels = Object.values(skeleton.label);

		const oneLabelIsAtLeastFilled = labels.some(label => {
			return !lod_.isNil(label) && !lod_.isEmpty(label);
		});

		if (!oneLabelIsAtLeastFilled) {
			return true;
		}

		return false;
	};

	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(skeleton);
		lod_.set(clone, path, value);
		setSkeleton(clone);
	};

	const handleSave = () => {
		let updatedItem = lod_.merge({ _id: item._id }, skeleton, mandatoryValues, defaultValues);

		onSave(updatedItem, edit);
	};

	useEffect(() => {
		if (open) {
			setSkeleton(createDictionarySkeleton(dictionary, item || {}));
		}
	}, [open]);

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={open}
			onClose={onClose}
			PaperProps={{
				style: {
					height: "80%",
					width: "100%",
					maxWidth: "80%"
				}
			}}
		>
			<DialogTitle>Métadonnée</DialogTitle>
			<DialogContent>
				<MDBox mt={2} bgColor="white" borderRadius="lg">
					<LittleForm
						object={dictionary}
						metadatasSkeleton={skeleton}
						handleChange={handleChange}
					/>
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled()} variant="contained" color="info" onClick={handleSave}>
					{edit ? i18n.t("SETTINGS.edit") : i18n.t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

/**
 * Default component to display charts, works with dynamic datas
 */
export default function MetadatasPage({ route }) {
	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);
	const filters = useSelector(state => state.filters);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(true);
	// Page Charts
	const [charts, setCharts] = useState([]);
	// When charts are loaded for the first time, avoid multiples requests
	const [firstLoad, setFirstLoad] = useState(true);

	const [deleteDialog, setDeleteDialog] = useState({
		open: false,
		item: {}
	});

	const [metadatasDialog, setMetadatasDialog] = useState({
		open: false,
		item: {},
		dictionary: {},
		edit: false
	});

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData(typeList) {
		return charts.filter(chart => typeList.includes(chart.customType || chart.type));
	}
	/**
	 * Get charts with filters from the back
	 */
	async function getCharts(requestFilters) {
		return new Promise((resolve, reject) => {
			dispatch(
				ChartsActions.getPageCharts(
					profile.assistantID,
					route.route,
					requestFilters,
					[],
					route.system ?? false,
					res => resolve(res.charts)
				)
			);
		});
	}

	/* Load charts from back */
	async function loadCharts(defaultFilters = null) {
		// Get filters from local storage
		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);
		// If there is default filters, set them (ONLY ON PAGE LOAD)
		if (!lod_.isEmpty(defaultFilters)) {
			Object.keys(actualFilters).map(key => {
				let filter = actualFilters[key];
				let defaultFilter = defaultFilters[key];
				// Replace value if it is empty
				if (lod_.isEmpty(filter?.values)) {
					actualFilters[key] = {
						...defaultFilter
					};
				}
			});
		}
		// Build charts with filters
		let chartsFromDatabase = await getCharts(actualFilters);
		setCharts(chartsFromDatabase);
		setChartsLoading(false);
	}

	function filterDictionary(dictionaryItems, allowedPaths = [], path = "") {
		let result = {};

		function recursiveFilter(obj, allowedPaths, path) {
			// Create a copy of the object to avoid modifying the original
			const filteredObj = { ...obj };

			const endPathAll = ".*";

			const match = allowedPaths.find(allowedPath => {
				if (allowedPath.endsWith(endPathAll)) {
					return path.startsWith(allowedPath.slice(0, -endPathAll.length));
				}
				return path === allowedPath;
			});

			if (!allowedPaths.includes(path) && !match) {
				return null;
			}

			// Check if object has 'items' to filter
			if (filteredObj.items) {
				const filteredItems = {};

				// Filter each sub-object
				for (const key in filteredObj.items) {
					if (Object.prototype.hasOwnProperty.call(filteredObj.items, key)) {
						// Filter the sub-object
						let newPath = path ? `${path}.${key}` : key;
						const filteredSubItem = recursiveFilter(filteredObj.items[key], allowedPaths, newPath);
						if (filteredSubItem !== null) {
							filteredItems[key] = filteredSubItem;
						}
					}
				}

				// Replace the items with the filtered items
				filteredObj.items = filteredItems;
			}

			return filteredObj;
		}

		// Start the recursive filtering
		for (let key of Object.keys(dictionaryItems)) {
			let item = dictionaryItems[key];
			let filtered = recursiveFilter(item, allowedPaths, path || key);
			if (filtered !== null) {
				result[key] = filtered;
			}
		}

		return result;
	}

	/**
	 * Which fields are visible in the metadatas form
	 * @param {object} dictionary - Dictionary to check
	 * @returns {object} - Dictionary with visible fields
	 */
	const setVisibleFields = dictionary => {
		const visible = [
			"code",
			"label.*",
			"description",
			"actions",
			"actions.extractFaiqMetadata",
			"whitelist"
		];

		return filterDictionary(dictionary, visible);
	};

	/**
	 * Delete item handler
	 * @param {string} id - Item id
	 */
	const deleteItem = id => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			loadCharts();
		};
		dispatch(FormActions.deleteItem(id, "dictionary", {}, onSuccess));
	};

	/**
	 * Close metadatas dialog handler
	 */
	const handleCloseMetadatasDialog = () => {
		setMetadatasDialog({ open: false, item: {}, dictionary: {}, edit: false });
	};

	/**
	 * Save metadatas dialog handler
	 * @param {object} values - Values to save
	 * @param {boolean} edit - If it is an edit or not
	 */
	const handleSaveMetadatasDialog = (values, edit) => {
		handleCloseMetadatasDialog();

		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			loadCharts();
		};

		let data = { values, target: "dictionary" };
		if (!edit) {
			dispatch(FormActions.addItemEmpty(data, onSuccess, err => {}));
		} else {
			dispatch(FormActions.updateItem(values._id, data, onSuccess, err => {}));
		}
	};

	/**
	 * Load charts when assistant changes or route changes
	 */
	useEffect(() => {
		setFirstLoad(true);
		setChartsLoading(true);

		async function load() {
			// Load the charts with default filters
			loadCharts();
			// Avoid multiples requests
			setFirstLoad(false);
		}

		load();
	}, [profile.selectedAssistant.assistantID, route]);
	/**
	 * When filters change, reload charts
	 */
	useEffect(() => {
		if (!firstLoad) {
			loadCharts();
		}
	}, [filters]);
	/* Charts loader */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else {
		/* Main component */
		return (
			<DashboardLayout>
				{/* Dictionaries */}
				{getChartsData(["dictionary"]).map((chart, index) => {
					let data = chart?.data?.dictionary.metadatas ?? {};
					let items = {};

					if (lod_.isEmpty(data)) {
						return null;
					}

					for (let key of Object.keys(data.items ?? {})) {
						let item = data.items[key];
						if (!item.system) {
							items[key] = item;
						}
					}

					lod_.set(chart, "data.dictionary.metadatas.items", items);

					return (
						<MDBox mb={2}>
							<DictionaryDataTable
								flat
								draggable
								display={chart.request.attributesDisplay}
								columns={chart?.data?.columns}
								dictionary={chart?.data?.dictionary}
								title={chart.display?.title}
								dictionaryCode={chart.code}
								actions={[
									<Tooltip placement="top" title={i18n.t("SETTINGS.edit")}>
										<IconButton
											handleclick={(object, event) => {
												event.stopPropagation();
												const onSuccess = res => {
													setMetadatasDialog({
														edit: true,
														open: true,
														item: object,
														dictionary: setVisibleFields(res.dictionary)
													});
												};

												dispatch(FormActions.getItemEmpty("dictionary", "dictionary", onSuccess));
											}}
										>
											<Icon fontSize="medium">edit</Icon>
										</IconButton>
									</Tooltip>,
									<Tooltip placement="top" title={i18n.t("SETTINGS.delete")}>
										<IconButton
											handleclick={(object, event) => {
												event.stopPropagation();
												// actionDeleteHandle(object, chart.request.collection);
												setDeleteDialog({ open: true, item: object });
											}}
										>
											<Icon fontSize="medium">delete</Icon>
										</IconButton>
									</Tooltip>
								]}
								dictionaryFormat={chart.dictionary?.dictionary}
								handleAddRow={datas => {
									const onSuccess = res => {
										setMetadatasDialog({
											edit: false,
											open: true,
											item: {},
											dictionary: setVisibleFields(res.dictionary),
											defaultValues: datas
										});
									};

									dispatch(FormActions.getItemEmpty("dictionary", "dictionary", onSuccess));
								}}
							/>
						</MDBox>
					);
				})}
				{/* Delete dialog */}
				<Dialog open={deleteDialog.open} onClose={() => setDeleteDialog({ open: false, item: {} })}>
					<DialogTitle>{i18n.t("FORMS.LABELS.delete")}</DialogTitle>
					<DialogContent>{`${i18n.t("FORMS.LABELS.confirmDelete")} ${deleteDialog.item.code}`}</DialogContent>
					<DialogActions>
						<MDButton
							autoFocus
							onClick={() => setDeleteDialog({ open: false, item: {} })}
							variant="outlined"
							color="info"
						>
							{i18n.t("FORMS.cancel")}
						</MDButton>
						<MDButton
							onClick={() => {
								deleteItem(deleteDialog.item._id);
								setDeleteDialog({ open: false, item: {} });
							}}
							color="info"
							variant="contained"
							autoFocus
						>
							{i18n.t("FORMS.validate")}
						</MDButton>
					</DialogActions>
				</Dialog>
				{/* Metadatas dialog */}
				<MetadatasDialog
					open={metadatasDialog.open}
					{...metadatasDialog}
					onClose={handleCloseMetadatasDialog}
					onSave={handleSaveMetadatasDialog}
				/>
			</DashboardLayout>
		);
	}
}
