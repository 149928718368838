/* eslint-disable react/jsx-no-bind */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import lod_ from "lodash";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import { createDictionarySkeleton } from "helpers/form";

export default function EditKnowledgeDialog({
	open,
	handleCloseDialog,
	handleSave,
	knowledge = {},
	metadatasDictionary
}) {
	// Future knowledge
	const [copyKnowledge, setCopyKnowledge] = useState({});
	// Metadatas skeleton
	const [metadatasSkeleton, setMetadatasSkeleton] = useState(
		createDictionarySkeleton(metadatasDictionary, knowledge?.metadatas || {})
	);
	// Disable button
	const isDisabled = copyKnowledge.content === "";
	// Close dialog
	function close() {
		handleCloseDialog();
	}
	// Save knowledge
	function sumbit() {
		handleSave({
			uuid: knowledge.uuid,
			content: copyKnowledge.content,
			metadatas: {
				...metadatasSkeleton,
				source: knowledge.metadatas?.source
			}
		});
		close();
	}
	// Change metadatas
	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(metadatasSkeleton);
		lod_.set(clone, path, value);
		setMetadatasSkeleton(clone);
	};
	// Set knowledge when dialog open
	useEffect(() => {
		setCopyKnowledge(knowledge ?? {});
	}, [open === true]);

	return (
		<Dialog fullWidth maxWidth="xl" open={open} onClose={() => close()}>
			<DialogTitle>{i18n.t("KNOWLEDGE.edit")}</DialogTitle>
			<DialogContent>
				{/* Content */}
				<MDBox mt={2}>
					<MDInput
						className="dialogInput"
						label={i18n.t("KNOWLEDGE.ADD.content")}
						value={copyKnowledge.content ?? ""}
						multiline
						rows={7}
						inputProps={{ maxLength: 2000 }}
						onChange={e =>
							setCopyKnowledge(copy => {
								return {
									...copy,
									content: e.target.value
								};
							})
						}
					/>
				</MDBox>
				<MDTypography variant="caption" color="textSecondary">
					{copyKnowledge.content?.length || 0}/2000
				</MDTypography>
				{/* Metadatas */}
				<MDBox flex="2" mt={4}>
					<MDTypography variant="h5">{i18n.t("SOURCES.metadatas")}</MDTypography>
					<MDBox mt={2}>
						<LittleForm
							object={metadatasDictionary}
							metadatasSkeleton={metadatasSkeleton}
							handleChange={handleChange}
						/>
					</MDBox>
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={() => close()}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled} variant="contained" color="info" onClick={sumbit}>
					{i18n.t("SETTINGS.edit")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
