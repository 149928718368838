/* eslint-disable import/prefer-default-export */
import { CHAT_MODAL_MODES } from "constants";

export const getComponentsInfos = currentMode => {
	switch (currentMode) {
		case CHAT_MODAL_MODES.ASSISTANT:
			return {
				mainColor: "info",
				secondColor: "white"
			};
		case CHAT_MODAL_MODES.HELP:
			return {
				mainColor: "faibrik",
				secondColor: "white"
			};
		default:
			return {
				mainColor: "white",
				secondColor: "info"
			};
	}
};
