/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import lod_ from "lodash";
import SettingsActions from "redux-react/actions/settingsActions";
/**
 * Query a dictionary with promise
 * @param {string} code - Code of the dictionary
 * @returns {Promise} - Promise with the dictionary
 */
export async function queryDictionary(dispatch, code) {
	return new Promise((resolve, reject) => {
		dispatch(
			SettingsActions.getDictionary(code, res => {
				resolve(res.dictionary);
			})
		);
	});
}

/**
 * Query dictionaries from the API
 * @param {<string>} value
 * @returns {Promise} - Promise with the dictionaries
 */
export async function queryDictionaries(dispatch, codes) {
	const dictionaries = {};

	for (let codeDictionary of codes) {
		let dictionary = await queryDictionary(dispatch, codeDictionary);
		dictionaries[codeDictionary] = dictionary;
	}

	return dictionaries;
}

/**
 * Get all path from an object
 * @param {*} obj
 * @param {*} currentPath
 * @returns
 */
export function getAllPaths(obj, currentPath = "") {
	return lod_.flatMap(lod_.keys(obj), key => {
		const newPath = currentPath ? `${currentPath}.${key}` : key;
		if (lod_.isObject(obj[key]) && !lod_.isArray(obj[key])) {
			return getAllPaths(obj[key], newPath);
		}
		return newPath;
	});
}

/**
 * Convert path into dictionary path "context.name" => "context.items.name"
 * @param {*} path
 * @returns
 */
export function getDictionaryPath(path) {
	return path.replaceAll(".", ".items.");
}

/**
 * Get the label from a dictionary's item
 * @param {*} labels
 * @param {*} path
 * @param {*} language
 * @returns
 */
export function getLabel(labels, language = "fr") {
	let label = labels[language];
	if (!label) {
		label = labels[Object.keys(labels)[0]];
	}
	return label;
}

/**
 * Get dictionary label frompath
 * @param {object} dictionary - Dictionary
 * @param {string} path - Path
 * @param {string} defaultValue - Default value
 * @returns {string} - The label
 */
export const getLabelPath = (dictionary, path, defaultValue = "None") => {
	if (!path) {
		return defaultValue;
	}

	const parts = path.split(".");
	const result = [];
	let current = "";

	for (let i = 0; i < parts.length; i++) {
		current += parts[i];
		result.push(current);
		current += ".items.";
	}

	const stringParts = [];

	for (let part of result) {
		let partLabel = lod_.get(dictionary, part + ".label");
		if (partLabel) {
			partLabel = getLabel(partLabel, "fr");
		}

		if (partLabel) {
			stringParts.push(partLabel);
		}
	}

	return stringParts.join(" / ");
};
