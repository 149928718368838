/* eslint-disable consistent-return */
import MDAlert from "components/Basics/MDAlert";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { selectNotifications } from "redux-react/reducers/notificationsReducer";
import { selectNotificationsList, selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { selectSnackBar, hide, removeMessage } from "redux-react/reducers/snackBarReducer";
/**
 * #######################################################@
 *
 * SnackBar component
 *
 * Used to display a message in a snackbar by the store
 *
 * #######################################################@
 */
import { Snackbar, Alert, Icon, Slide, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const SnackBarMessage = () => {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);
	const snackBar = useSelector(selectSnackBar);
	const notifications = useSelector(selectNotifications);
	const notificationsList = useSelector(selectNotificationsList);

	const handleClose = id => {
		dispatch(removeMessage({ id }));
	};

	const [alertContent, setAlertContent] = useState("");

	const [showAlert, setShowAlert] = useState(false);

	const [oldLength, setOldLength] = useState({});

	const getSeverity = type => {
		if (type === "loading") {
			return "info";
		}

		return type ?? "info";
	};

	useEffect(() => {
		if (!notificationsList.length) {
			setOldLength({
				...oldLength,
				[profile.assistantID]: 0
			});
			return;
		}

		if (notificationsList.length > (oldLength[profile.assistantID] || 0)) {
			setOldLength({
				...oldLength,
				[profile.assistantID]: notificationsList.length
			});
			setAlertContent(notificationsList[notificationsList.length - 1].message);
			setShowAlert(() => {
				return false;
			});
			setShowAlert(() => {
				return true;
			});

			const timer = setTimeout(() => {
				setShowAlert(() => {
					return false;
				});
			}, 3000);

			// return () => clearTimeout(timer);
		}
	}, [notifications, dispatch]);

	return (
		<>
			{/*
			 * Notifications
			 */}
			<div className="notificationsContainer">
				<Slide in={showAlert} direction="down">
					<div>
						<MDAlert>
							<Icon fontSize="small">notifications</Icon>
							<MDTypography variant="body2" color="white">
								{alertContent ?? "Nouvelle notification !"}
							</MDTypography>
						</MDAlert>
					</div>
				</Slide>
			</div>
			{/*
			 * SnackBar
			 */}
			{snackBar.messages.map(message => (
				<Snackbar
					key={message.id}
					open={snackBar.open}
					autoHideDuration={message.validation || message.type === "loading" ? null : 4000}
					onClose={() =>
						message.validation || message.type === "loading" ? null : handleClose(message.id)
					}
				>
					<Alert
						icon={message.type === "loading" ? false : null}
						severity={getSeverity(message.type)}
						style={{
							display: "flex",
							alignItems: "center"
						}}
					>
						<MDBox display="flex" alignItems="center">
							{message.type === "loading" && (
								<CircularProgress color="info" size={20} style={{ marginRight: 10 }} />
							)}
							{message.message ?? ""}
							{message.validation ? message.validation : null}
						</MDBox>
					</Alert>
				</Snackbar>
			))}
		</>
	);
};

export default SnackBarMessage;
