import { CircularProgress, Fade } from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import MDBox from "components/Basics/MDBox";
import { t } from "i18next";

/**
 * Display a loading spinner while files are being processed
 * @returns {JSX.Element} - SubmitInProgress
 */
const SubmitInProgress = () => {
	return (
		<Fade in>
			<MDBox
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				sx={{ height: "100%" }}
			>
				<MDBox textAlign="center">
					<MDTypography variant="h5">{t("SOURCES.FILES.processingFiles")}</MDTypography>
					<MDTypography variant="subtitle2">{t("SOURCES.FILES.waitWhileLoading")}</MDTypography>
				</MDBox>
				<CircularProgress sx={{ mt: 15 }} color="info" size={80} />
			</MDBox>
		</Fade>
	);
};

export default SubmitInProgress;
