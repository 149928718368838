/* eslint-disable react/jsx-no-bind */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useRef, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import StepSelectImportMethod from "./addSourceSteps/1. ImportMethod";
import StepSelection from "./addSourceSteps/2. Selection";
import StepDisplay from "./addSourceSteps/3. Display";
import { importSources } from "hooks/sources";
import { useDispatch } from "react-redux";
import StepMetadatas from "./addSourceSteps/4. Metadatas";
import SubmitInProgress from "./components/SubmitInProgress";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import { t } from "i18next";

/**
 * Add sources dialog
 * User import files from different sources
 * @param {*} param0
 * @returns {JSX.Element} AddSourceDialog
 */
export default function AddSourceDialog({ open, handleCloseDialog, metadatas }) {
	const dispatch = useDispatch();
	const topContainer = useRef(null);

	const [submitInProgress, setSubmitInProgress] = useState(false);

	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);

	/**
	 * Steps labels
	 */
	const [steps, setSteps] = useState([
		t("SOURCES.STEPS.import"),
		t("SOURCES.STEPS.upload"),
		t("SOURCES.STEPS.filesChoice"),
		t("SOURCES.STEPS.metadatas")
	]);
	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);

	const [skeleton, setSkeleton] = useState({
		importMethod: null, // sitemap | url | localFile | shopifyPage | ...
		localFiles: [], // local file objects, used for localFile import method
		files: [], // Imported files
		filesToProcess: [], // {url, name, alreadyExists, image?} files selected by user to process
		metadatas: {}, // Metadatas to save,
		extra: {} // Extra data
	});

	/**
	 * Is last step
	 */
	const isLastStep = activeStep === steps.length - 1;

	/**
	 * Close dialog
	 */
	function close() {
		setActiveStep(0);
		setStepValid(false);
		setSkeleton({
			importMethod: null,
			localFiles: [],
			files: [],
			filesToProcess: [],
			metadatas: {},
			extra: {}
		});
		handleCloseDialog();
	}

	/**
	 * Save the sources and close the dialog
	 */
	async function sumbit() {
		setSubmitInProgress(true);
		const resultImport = await importSources(dispatch, skeleton);
		setSubmitInProgress(false);
		close();
	}

	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};

	/**
	 * Go to back step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};

	/**
	 * Go to next step
	 */
	const handleNext = () => {
		setActiveStep(activeStep + 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};

	/**
	 * User select import method (or add directly files)
	 * @param {string} props.importMethod - Import method
	 * @param {Array<string>} props.files - Files to import
	 */
	const handleSaveImportMethod = ({ importMethod, localFiles = [], files = [], extra = {} }) => {
		setSkeleton(skeleton => {
			return {
				...skeleton,
				extra,
				importMethod,
				localFiles,
				files,
				filesToProcess: [],
				metadatas: {}
			};
		});

		handleNext();
	};

	/**
	 * Transform files informations to correct files format
	 * @param {Array<object>} files - Files to import
	 */
	const handleSaveFilesSelection = files => {
		setSkeleton(skeleton => {
			return {
				...skeleton,
				files,
				filesToProcess: [],
				metadatas: {}
			};
		});

		validStep();
		handleNext();
	};

	/**
	 * User select files to process
	 * @param {Array<object>} files - Files to process format: {url, name, alreadyExists, image?}
	 */
	const onChangeFilesToProcess = files => {
		setSkeleton(skeleton => {
			return {
				...skeleton,
				filesToProcess: files,
				metadatas: {}
			};
		});
	};

	/**
	 * User change a metadatas -> update skeleton
	 * @param {object} metadatas - Metadatas
	 */
	const onChangeMetadatas = metadatas => {
		setSkeleton(skeleton => {
			return {
				...skeleton,
				metadatas
			};
		});
	};

	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return <StepSelectImportMethod handleSave={handleSaveImportMethod} onClose={close} />;
			case 1: {
				return (
					<StepSelection
						skeleton={skeleton}
						handleSave={handleSaveFilesSelection}
						validStep={validStep}
					/>
				);
			}
			case 2: {
				return (
					<StepDisplay
						skeleton={skeleton}
						onChange={onChangeFilesToProcess}
						validStep={validStep}
					/>
				);
			}
			case 3: {
				return (
					<StepMetadatas
						skeleton={skeleton}
						onChange={onChangeMetadatas}
						validStep={validStep}
						metadatas={metadatas}
					/>
				);
			}
			default:
				return null;
		}
	}

	const getNextButton = () => {
		switch (activeStep) {
			case 0:
				return null;
			case 2: {
				const someFilesAlreadyExists = skeleton.filesToProcess.some(file => file.alreadyExists);
				if (someFilesAlreadyExists) {
					return (
						<ConfirmDialogButton
							onConfirm={(e, values) => {
								handleNext();
							}}
							component={
								<MDButton
									disabled={!stepValid || skeleton.filesToProcess.length < 1}
									variant="contained"
									color="info"
								>
									{`${t("SOURCES.next")} (${skeleton.filesToProcess.length}) ${t("SOURCES.nextFiles")}`}
								</MDButton>
							}
							title={t("SOURCES.IMPORT.titleWarn")}
							content={t("SOURCES.IMPORT.subtitleWarn")}
						/>
					);
				} else {
					return (
						<MDButton
							disabled={!stepValid || skeleton.filesToProcess.length < 1}
							variant="contained"
							color="info"
							onClick={handleNext}
						>
							{`${t("SOURCES.next")} (${skeleton.filesToProcess.length}) ${t("SOURCES.nextFiles")}`}
						</MDButton>
					);
				}
			}
			case 1:
			case 3:
			default:
				return (
					<MDButton
						disabled={!stepValid}
						variant="contained"
						color="info"
						onClick={!isLastStep ? handleNext : sumbit}
					>
						{!isLastStep ? t("SETTINGS.next") : t("SETTINGS.import")}
					</MDButton>
				);
		}
	};

	return (
		<Dialog
			fullWidth
			maxWidth="xxl"
			PaperProps={{
				sx: {
					height: "90%"
				}
			}}
			open={open}
			onClose={close}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			{submitInProgress ? (
				<SubmitInProgress />
			) : (
				<>
					<DialogContent>
						<div data-id="top-container" ref={topContainer}></div>
						<MDBox mt={2} style={{ height: "90%" }}>
							{getStepContent(activeStep)}
						</MDBox>
					</DialogContent>
					<DialogActions>
						<MDButton variant="outlined" color="info" onClick={close}>
							{i18n.t("SETTINGS.cancel")}
						</MDButton>
						{activeStep > 0 && (
							<MDButton variant="contained" color="light" onClick={handleBack}>
								{t("SETTINGS.CHARTS.NEW.STEPS.back")}
							</MDButton>
						)}
						{getNextButton()}
					</DialogActions>
				</>
			)}
		</Dialog>
	);
}
