import { api } from "./api";

const actions = {
	/* Create a testing question */
	createTestingQuestion: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/testing/question`,
					data: {
						question: data.question,
						questionID: data.questionID ?? null
					},
					onSuccess,
					onFailure
				})
			);
		};
	},
	/* Delete a testing question */
	deleteTestingQuestion: (questions = [], onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/testing/question`,
					data: {
						questions
					},
					onSuccess,
					onFailure
				})
			);
		};
	},
	/* Create many testing question */
	createManyTestingQuestion: (questions, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/testing/question/many`,
					data: {
						questions
					},
					onSuccess,
					onFailure
				})
			);
		};
	},
	/* Create testing association between question and knowledges */
	createTestingAssociation: (questionID, knowledges, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/testing/association`,
					data: {
						questionID,
						knowledges
					},
					onSuccess,
					onFailure
				})
			);
		};
	},
	/* Delete testing association between question and knowledges */
	deleteTestingAssociation: (
		questionID,
		associationID,
		knowledgeID,
		onSuccess,
		onFailure = () => {}
	) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/testing/association`,
					data: {
						questionID,
						associationID,
						knowledgeID
					},
					onSuccess,
					onFailure
				})
			);
		};
	},
	/* Test one question */
	startTestingProcess: (questionID, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/testing/start`,
					data: {
						questionID
					},
					onSuccess,
					onFailure
				})
			);
		};
	},
	/* Test one question */
	startTestingProcessForAll: (onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/testing/start/all`,
					data: {},
					onSuccess,
					onFailure
				})
			);
		};
	}
};

export default actions;
