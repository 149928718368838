import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Badge,
	Card,
	Divider,
	Icon,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	Tooltip
} from "@mui/material";
import i18n from "i18n";
import FormAction from "redux-react/actions/formAction";
import TestingAction from "redux-react/actions/testingActions";
import { display } from "redux-react/reducers/snackBarReducer";
import KnowledgeDisplay from "./KnowledgeDisplay";
import MDInput from "components/Basics/MDInput";

export default function RightPannel({ questionCode, reloadTable, closePannel }) {
	const [question, setQuestion] = useState(null);

	const [selectedKnowledges, setSelectedKnowledges] = useState([]);
	const [menuRef, setMenuRef] = useState(null);
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();

	const [knwoledgeSearch, setKnowledgeSearch] = useState("");
	const [foundedKnowledges, setFoundedKnowledges] = useState([]);

	const selectKnowledge = uuid => {
		if (selectedKnowledges.includes(uuid)) {
			setSelectedKnowledges(selectedKnowledges.filter(k => k !== uuid));
		} else {
			setSelectedKnowledges([...selectedKnowledges, uuid]);
		}
	};

	const createAssociation = async () => {
		dispatch(
			display({
				message: "Association des connaissances à la question en cours...",
				type: "info"
			})
		);

		const { err } = await new Promise(resolve => {
			dispatch(
				TestingAction.createTestingAssociation(
					question.uuid,
					selectedKnowledges,
					res => resolve({ res }),
					err => resolve({ err })
				)
			);
		});

		if (err) {
			dispatch(
				display({
					message: "Erreur lors de l'association",
					type: "error"
				})
			);
		} else {
			dispatch(
				display({
					message: "Association effectuée avec succès",
					type: "success"
				})
			);
		}
	};

	const actionsMenu = () => {
		return (
			<Menu
				open={Boolean(menuRef)}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
				}}
			>
				{/* Options */}
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.actions`)}</MDTypography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setMenuRef(null);
						setSelectedKnowledges([]);
						createAssociation();
					}}
				>
					<Icon fontSize="medium">link</Icon>&nbsp;Associer à la question
				</MenuItem>
			</Menu>
		);
	};

	useEffect(() => {
		if (lod_.isNil(question) || lod_.isEmpty(question)) {
			return;
		}

		if (knwoledgeSearch.trim() === "" || knwoledgeSearch.length < 5) {
			setFoundedKnowledges([]);
			return;
		}

		let exceptionUUID = question?.knowledges.map(k => k.uuid) ?? [];

		let search = knwoledgeSearch.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
		let searchTerms = search.split(" ");
		let patternString = searchTerms.map(term => `(?=.*${term})`).join("");

		dispatch(
			FormAction.getItemsFromCollection(
				"faiqKnowledge",
				{
					query: {
						assistantID: profile.assistantID,
						content: { $regex: patternString, $options: "si" },
						active: { $in: [null, true] },
						uuid: { $nin: exceptionUUID }
					}
				},
				res => {
					setFoundedKnowledges(res.items.slice(0, 5));
				}
			)
		);
	}, [knwoledgeSearch]);

	const loadQuestionFromDB = async () => {
		if (lod_.isNil(questionCode)) {
			return;
		}

		dispatch(
			FormAction.getItemsFromCollection(
				"testing",
				{
					query: {
						$or: [
							{
								code: questionCode
							},
							{
								uuid: questionCode
							}
						],
						active: { $in: [null, true] }
					},
					projection: {
						associations: 1,
						uuid: 1,
						question: 1,
						knowledges: 1,
						minimumDistance: 1
					}
				},
				res => {
					if (res.items.length > 0) {
						setQuestion(res.items[0]);
					}
				}
			)
		);
	};

	const refreshPannel = () => {
		loadQuestionFromDB();
	};

	/**
	 * Load the question from database
	 */
	useEffect(() => {
		loadQuestionFromDB();
	}, [questionCode, reloadTable]);

	if (lod_.isNil(question)) {
		return null;
	}

	return (
		<MDBox className="rightPannel" sx={{ ml: 2 }} style={{ width: "30vw" }}>
			<Card sx={{ p: 2, height: "100%" }}>
				<MDBox display="flex" alignItems="start" justifyContent="space-between">
					{/* Left part */}
					<MDBox display="flex" justifyContent="start" alignItems="center">
						<MDTypography variant="body1" fontSize="medium">
							{question.question}
						</MDTypography>
					</MDBox>
					{/* Right part */}
					<MDBox ml={2}>
						<MDBox display="flex" justifyContent="end" alignItems="center">
							{/* Selection */}
							<Tooltip placement="top" title="Actions de selection">
								<Badge color="error" badgeContent={selectedKnowledges.length} overlap="circular">
									<IconButton
										disabled={selectedKnowledges.length === 0}
										onClick={e => {
											setMenuRef(e.currentTarget);
										}}
									>
										<Icon>apps</Icon>
									</IconButton>
								</Badge>
							</Tooltip>
							{actionsMenu()}
							{/* Close */}
							<Tooltip placement="top" title="Fermer">
								<IconButton onClick={closePannel}>
									<Icon>close</Icon>
								</IconButton>
							</Tooltip>
						</MDBox>
					</MDBox>
				</MDBox>

				<MDTypography
					variant="h6"
					fontSize="small"
				>{`${question.knowledges.length} ${i18n.t("FAIQ.TESTING.foundedKnowledges")} (Seuil minimum : ${question.minimumDistance})`}</MDTypography>

				<MDInput
					sx={{ mt: 1 }}
					fullWidth
					label="Recherche"
					size="small"
					value={knwoledgeSearch}
					onChange={e => setKnowledgeSearch(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="clear"
									onClick={() => {
										setKnowledgeSearch("");
									}}
								>
									<Icon>close</Icon>
								</IconButton>
							</InputAdornment>
						)
					}}
				/>

				<Divider />

				<MDBox
					style={{
						height: "100%",
						overflowY: "auto"
					}}
				>
					{foundedKnowledges.map((knowledge, index) => {
						return (
							<KnowledgeDisplay
								key={index}
								searched
								selectedQuestion={question}
								knowledge={knowledge}
								index={index}
								selectedKnowledges={selectedKnowledges}
								selectKnowledge={selectKnowledge}
								refreshPannel={refreshPannel}
							/>
						);
					})}
					{foundedKnowledges.length > 0 && <Divider />}
					{question.knowledges.map((knowledge, index) => {
						return (
							<KnowledgeDisplay
								key={index}
								selectedQuestion={question}
								knowledge={knowledge}
								index={index}
								selectedKnowledges={selectedKnowledges}
								selectKnowledge={selectKnowledge}
								refreshPannel={refreshPannel}
							/>
						);
					})}
				</MDBox>
			</Card>
		</MDBox>
	);
}
