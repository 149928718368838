import { useEffect, useState } from "react";
import MDBox from "components/Basics/MDBox";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import { t } from "i18next";

/**
 * Display a table with all the files
 * User can select many files as he wants
 * @param {*} param0
 * @returns {JSX.Element} - StepDisplay
 */
export default function StepDisplay({ skeleton, validStep, onChange }) {
	const [selectedRows, setSelectedRows] = useState(skeleton.filesToProcess);

	useEffect(() => {
		validStep();
	}, []);

	return (
		<MDBox flex="1" style={{ height: "100%", width: "100%", overflow: "auto" }}>
			<DefaultDataTable
				compressMode
				checkbox
				selectedRows={selectedRows}
				handleSelection={values => {
					setSelectedRows(values);
					onChange(values);
				}}
				isSelectedRow={row => {
					const existingRow = selectedRows.find(r => r.url === row.url);
					return Boolean(existingRow);
				}}
				//
				canSearch
				table={{
					rows: skeleton.files,
					columns: [
						{
							Header: t("SOURCES.DISPLAY.name"),
							accessor: "name"
						},
						{
							Header: t("SOURCES.DISPLAY.state"),
							accessor: "alreadyExists"
						},
						{
							Header: t("SOURCES.DISPLAY.url"),
							accessor: "url"
						}
					]
				}}
				noHeader
				pagination={{
					defaultEntrie: 25,
					entriesPerPage: [15, 25, 50, 100, 200]
				}}
			/>
		</MDBox>
	);
}
