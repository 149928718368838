/* eslint-disable react/no-danger */
import "./style.css";
import { Avatar, Icon, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useState } from "react";
import TestingQuestionDialog from "components/Custom/TestingQuestionDialog/TestingQuestionDialog";
import { useSelector } from "react-redux";
import { t } from "i18next";

/**
 *
 * @param {*} source - Source object
 * @param {*} displayedSource - Index of the displayed source
 * @param {*} sourcesLength - Length of the sources array
 * @param {*} nextSource - Function to display the next source
 * @param {*} previousSource - Function to display the previous source
 * @param {*} isOpen - Is the message open or not
 * @returns
 */
const SourceItem = ({
	source,
	displayedSource,
	sourcesLength,
	nextSource,
	previousSource,
	isOpen = true
}) => {
	return (
		<MDBox mt={!isOpen ? 0 : 3}>
			<MDBox display="flex" justifyContent="space-between" alignItems="center">
				{sourcesLength > 1 && (
					<IconButton onClick={() => previousSource()}>
						<Icon fontSize="small">arrow_back</Icon>
					</IconButton>
				)}
				{sourcesLength > 1 && (
					<MDBox flex="1" display="flex" alignItems="center" justifyContent="center">
						<MDTypography variant="h6" fontSize="small">
							{displayedSource + 1}/{sourcesLength}
						</MDTypography>
					</MDBox>
				)}
				{sourcesLength > 1 && (
					<IconButton onClick={() => nextSource()}>
						<Icon fontSize="small">arrow_forward</Icon>
					</IconButton>
				)}
			</MDBox>
			<MDBox
				flex="1"
				component="a"
				href={source.url}
				target="_blank"
				rel="noreferrer"
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="flex-start"
				mt={1}
				borderRadius="xxl"
				p={1}
				style={{
					cursor: "pointer"
				}}
				bgColor="light"
			>
				{isOpen && (
					<MDBox mr={1}>
						<Avatar
							alt={source.title.toUpperCase()}
							src={source.image}
							sx={{ width: 32, height: 32 }}
							imgProps={{
								style: {
									border: 0,
									objectFit: "cover",
									height: "100%"
								}
							}}
						></Avatar>
					</MDBox>
				)}
				<MDBox
					style={{
						overflow: "hidden"
					}}
				>
					<MDTypography variant="h6" fontSize="small" className="sourceChatTitle">
						{source.title}
					</MDTypography>
					{isOpen && (
						<MDTypography
							variant="body2"
							className="sourceChatDescription"
							style={{
								fontSize: 12
							}}
						>
							{source.description}
						</MDTypography>
					)}
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

/**
 * Message component
 * @returns
 */
const Message = ({
	id,
	threadID,
	message,
	sources = [],
	direction,
	loading = false,
	open = true
}) => {
	const permissions = useSelector(state => state.permissions?.permissions);

	const [displayedSource, setDisplayedSource] = useState(0);
	const [isOpen, setIsOpen] = useState(open);

	const [testingQuestionDialog, setTestingQuestionDialog] = useState({
		open: false
	});

	const nextSource = () => {
		setDisplayedSource(prev => (prev + 1) % sources.length);
	};

	const previousSource = () => {
		let sourcesLength = sources.length;
		let newDisplayedSource = displayedSource - 1;
		if (newDisplayedSource < 0) {
			newDisplayedSource = sourcesLength - 1;
		}
		setDisplayedSource(newDisplayedSource);
	};

	const containerStyle = {
		width: "100%",
		display: "flex"
	};

	const style = {
		boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
		borderRadius: "20px",
		width: "fit-content",
		padding: "10px",
		maxWidth: "80%",
		marginTop: "10px"
	};

	switch (direction) {
		case "in":
			containerStyle.justifyContent = "flex-start";
			style.backgroundColor = "#ffffff";
			break;
		case "out":
			containerStyle.justifyContent = "flex-end";
			style.backgroundColor = "#1A73E8";
			style.color = "white";
			break;
		default:
			break;
	}

	if (loading) {
		return (
			<MDBox borderRadius="md" className="chat-bubble" mt={1} style={style} p={1}>
				<div className="typing">
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
				</div>
			</MDBox>
		);
	}

	// Style when the message is reduced
	const reduceModeStyle = {
		fontSize: 12,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		height: "20px"
	};

	// Default style
	const defaultStyle = {
		fontSize: 12
	};

	const onClose = () => {
		setTestingQuestionDialog({ open: false });
	};

	return (
		<MDBox>
			<TestingQuestionDialog
				{...testingQuestionDialog}
				questionID={id}
				threadID={threadID}
				open={testingQuestionDialog.open}
				onClose={onClose}
			/>
			<MDBox className="faiq-in-message" style={containerStyle}>
				<MDBox mt={1} style={style}>
					<p
						style={isOpen ? defaultStyle : reduceModeStyle}
						dangerouslySetInnerHTML={{ __html: message }}
					></p>
					<MDBox /* display="flex" flexDirection="row" alignItems="end" */>
						{direction === "in" && (
							<MDBox display="flex" flexDirection="row" justifyContent="end">
								{permissions.includes("exec:overtrainingFromExchange") && (
									<Tooltip placement="top" title={t("FAIQ.TESTING.overtrainingFromThisMessage")}>
										<IconButton
											fontSize="small"
											variant="contained"
											onClick={() => setTestingQuestionDialog({ open: true })}
										>
											<Icon fontSize="small">mode_standby</Icon>
										</IconButton>
									</Tooltip>
								)}
								<Tooltip placement="top" title={t("SETTINGS.openAndClose")}>
									<IconButton
										fontSize="small"
										variant="contained"
										onClick={() => setIsOpen(!isOpen)}
									>
										<Icon fontSize="small">{isOpen ? "close_fullscreen" : "open_in_full"}</Icon>
									</IconButton>
								</Tooltip>
							</MDBox>
						)}
						<MDBox flex="1">
							{sources.map((source, index) => {
								if (displayedSource === index) {
									return (
										<SourceItem
											key={index}
											source={source}
											displayedSource={displayedSource}
											sourcesLength={sources.length}
											nextSource={nextSource}
											previousSource={previousSource}
											isOpen={isOpen}
											setIsOpen={setIsOpen}
										/>
									);
								} else {
									return null;
								}
							})}
						</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default Message;
