import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	// Train a list of knowledges
	trainKnowledges: (uuid, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/knowledge/train`,
					data: {
						uuid
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de l'entrainement des connaissances"
							})
						);
					}
				})
			);
		};
	},
	// Train all knowledges that are not trained
	trainAllKnowledges: onSuccess => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/knowledge/train/all`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de l'entrainement des connaissances"
							})
						);
					}
				})
			);
		};
	},
	// Train all knowledges from a source
	trainAllKnowledgesFromSources: (sources, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/knowledge/train/sources`,
					data: {
						sources
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de l'entrainement des connaissances"
							})
						);
					}
				})
			);
		};
	},
	/* Get knowledges by sources code */
	getKnowledgesBySourceCode: (code, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/knowledge/source`,
					data: {
						code
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	}
};

export default actions;
