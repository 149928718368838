/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { CircularProgress, Icon } from "@mui/material";
import lod_ from "lodash";
import { LittleForm } from "components/Custom/LittleForm";
import MDInput from "components/Basics/MDInput";
import { createDictionarySkeleton } from "helpers/form";
import { useDispatch } from "react-redux";
import FaiqActions from "redux-react/actions/faiqActions";
import { t } from "i18next";

export default function Step1Metadatas({
	skeleton,
	source,
	validStep,
	handleSave,
	metadatas,
	lockGenerateButton
}) {
	const dispatch = useDispatch();

	const [metadatasSkeleton, setMetadatasSkeleton] = useState(
		createDictionarySkeleton(metadatas, source.metadatas)
	);

	const generateMetadatas = () => {
		lockGenerateButton();
		dispatch(FaiqActions.startFAIQPipeline([source.code], ["metadatas"], () => {}));
	};

	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(metadatasSkeleton);
		lod_.set(clone, path, value);
		handleSave(clone);
		setMetadatasSkeleton(clone);
	};

	useEffect(() => {
		validStep();
		handleSave(metadatasSkeleton);
	}, []);

	useEffect(() => {
		setMetadatasSkeleton(createDictionarySkeleton(metadatas, source.metadatas));
	}, [source]);

	return (
		<MDBox
			sx={{ ml: 10, mr: 10 /* overflowY: "auto" */ }}
			flex="1"
			display="flex"
			flexDirection="column"
			justifyContent="center"
		>
			<MDBox display="flex" alignItems="center" flexDirection="row" justifyContent="end">
				<MDBox alignItems="center">
					<MDButton
						color="info"
						onClick={generateMetadatas}
						disabled={skeleton.metadatasGenerationDisabled}
					>
						{t("SOURCES.PIPELINE.ACTIONS.generateMetadatas")}&nbsp;
						{skeleton.metadatasGenerationDisabled ? (
							<CircularProgress size={16} color="white" />
						) : (
							<Icon>auto_awesome</Icon>
						)}
					</MDButton>
				</MDBox>
			</MDBox>
			<MDBox
				mt={1}
				flex="1"
				style={{
					overflowY: "auto"
				}}
			>
				<LittleForm
					object={metadatas}
					metadatasSkeleton={metadatasSkeleton}
					handleChange={handleChange}
				/>
				{/* HTML */}
				{source.type === "html" && (
					<MDBox mt={4}>
						<MDTypography variant="h5">
							{t("SOURCES.PIPELINE.ACTIONS.metadatasTitleWeb")}
						</MDTypography>
						<MDInput
							sx={{ mt: 2 }}
							fullWidth
							label={t("SOURCES.PIPELINE.ACTIONS.metadatasWebLabel")}
							value={metadatasSkeleton.htmlSelector}
							onChange={e => {
								handleChange("htmlSelector", e.target.value);
							}}
						/>
					</MDBox>
				)}
			</MDBox>
		</MDBox>
	);
}
