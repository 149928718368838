/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
import "./style.css";
import { useEffect, useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import FaiqActions from "redux-react/actions/faiqActions";
import SourceActions from "redux-react/actions/sourceActions";
import MDTypography from "components/Basics/MDTypography";
import { CircularProgress, Grid, Icon } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DragAndDrop from "pages/sources/components/DragAndDrop";
import { IMPORT_METHODS, AVAILABLES_CHANNELS } from "constants";
import { t } from "i18next";
import MDBadge from "components/Basics/MDBadge";
import { wsEvent } from "redux-react/actions/ws";

/**
 * Sources step to select the import method (and to upload files if needed)
 * @param {*} param0
 * @returns {JSX.Element} - StepSelectImportMethod
 */
export default function StepSelectImportMethod({ handleSave, onClose }) {
	const profile = useSelector(state => state.profile);
	const permissions = useSelector(state => state.permissions?.permissions);
	const dispatch = useDispatch();

	const [loadingIntegrations, setLoadingIntegrations] = useState(true);
	const [possibleIntegrations, setPossibleIntegrations] = useState([]);
	const [channelAssistantID, setChannelAssistantID] = useState(null);

	/**
	 * User select an import method (url, sitemap, ...)
	 * @param {string} method - Import method
	 * @param {Array<File>} files - Files to import
	 */
	const selectMethod = (method, localFiles = [], files = [], extra = {}) => {
		handleSave({
			importMethod: method,
			localFiles: localFiles,
			files: files,
			extra: extra
		});
	};

	/**
	 * Load config
	 */
	function getPossibleIntegrations() {
		// Result function
		const onSuccess = res => {
			// If config is not nil
			if (!lod_.isNil(res?.config)) {
				const config = res.config;

				// Get the channel assistant ID (used to link faiq app with cockpit app)
				if (config.channelAssistantID) {
					setChannelAssistantID(config.channelAssistantID);

					// Get the possible integrations
					dispatch(
						SourceActions.getIntegrationChannels(
							{
								assistantID: config.channelAssistantID,
								channels: AVAILABLES_CHANNELS.map(channel => channel.type)
							},
							res => {
								if (!lod_.isNil(res)) {
									setPossibleIntegrations(res);
								}
								setLoadingIntegrations(false);
							},
							() => {
								setLoadingIntegrations(false);
							}
						)
					);
				} else {
					setLoadingIntegrations(false);
				}
			} else {
				setLoadingIntegrations(false);
			}
		};

		// Get the config
		setLoadingIntegrations(true);
		dispatch(
			FaiqActions.getFaiqConfig(profile.assistantID, onSuccess, () => {
				setLoadingIntegrations(false);
			})
		);
	}

	/**
	 * Load possible integrations on component mount
	 */
	useEffect(() => {
		getPossibleIntegrations();
	}, []);

	return (
		<MDBox flex="1" display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
			<MDBox flex="5">
				<Grid container>
					{permissions.includes("exec:importFaibrikAdminFAIQ") && (
						<Grid
							item
							xs={6}
							sm={4}
							md={3}
							lg={2}
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								alignItems: "center"
							}}
						>
							<MDBox
								className="integrationBox"
								display="flex"
								flexDirection="column"
								alignItems="center"
								justifyContent="space-between"
								borderRadius="lg"
								p={2}
								sx={{
									height: "100%",
									width: "100%"
								}}
								onClick={() => {
									dispatch(
										wsEvent({
											event: "specific_mobelia_import_products",
											payload: {}
										})
									);
									onClose();
								}}
							>
								<MDBox>
									<MDBadge circular badgeContent="Admin" container color="error" />
								</MDBox>
								<MDTypography variant="body1" fontSize="small">
									Mobellia
								</MDTypography>
							</MDBox>
						</Grid>
					)}
					{[
						{
							name: t("SOURCES.IMPORT_METHODS.web"),
							importMethod: IMPORT_METHODS.URL,
							icon: "website"
						},
						{
							name: t("SOURCES.IMPORT_METHODS.sitemap"),
							importMethod: IMPORT_METHODS.SITEMAP,
							icon: "share"
						},
						...possibleIntegrations
					].map((channel, index) => {
						const importMethod =
							channel.importMethod ??
							AVAILABLES_CHANNELS.find(c => c.type === channel.type)?.importMethod;

						return (
							<Grid
								item
								xs={6}
								sm={4}
								md={3}
								lg={2}
								key={index}
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									alignItems: "center"
								}}
							>
								<MDBox
									className="integrationBox"
									display="flex"
									flexDirection="column"
									alignItems="center"
									justifyContent="space-between"
									borderRadius="lg"
									p={2}
									sx={{
										height: "100%",
										width: "100%"
									}}
									onClick={() => {
										const extra = {
											channelAssistantID: channelAssistantID
										};

										if (channel.code) {
											extra.code = channel.code;
										}

										selectMethod(importMethod, [], [], extra);
									}}
								>
									{channel.icon && (
										<MDBox>
											<Icon fontSize="large">{channel.icon}</Icon>
										</MDBox>
									)}
									{channel.logoURL && (
										<MDBox>
											<MDBox component="img" src={channel.logoURL} width="35px" height="35px" />
										</MDBox>
									)}
									<MDTypography variant="body1" fontSize="small">
										{channel.name}
									</MDTypography>
								</MDBox>
							</Grid>
						);
					})}
					{loadingIntegrations && (
						<Grid
							item
							xs={6}
							sm={4}
							md={3}
							lg={2}
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								alignItems: "center"
							}}
						>
							<MDBox
								// className="integrationBox"
								display="flex"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								borderRadius="lg"
								p={2}
								sx={{
									height: "100%",
									width: "100%"
								}}
							>
								<CircularProgress color="info" size={40} />
							</MDBox>
						</Grid>
					)}
				</Grid>
			</MDBox>
			<MDBox ml={1} flex="3" style={{ height: "100%", width: "100%" }}>
				<DragAndDrop
					handleFiles={files => {
						// Save files in localFiles
						selectMethod(IMPORT_METHODS.LOCAL_FILE, files);
					}}
				/>
			</MDBox>
		</MDBox>
	);
}
