import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
	open: false,
	messages: [], // Array to store multiple messages
	type: "info"
};

export const snackBar = initialState;

const slice = createSlice({
	name: "snackBar",
	initialState,
	reducers: {
		display: (state, action) => {
			const { message, type, id = uuidv4() } = action.payload;
			state.messages.push({
				id,
				message,
				type
			});
			state.type = type;
			state.open = true;
		},
		hide: state => {
			state.open = false;
			state.messages = [];
		},
		updateMessage: (state, action) => {
			const { id, message, type } = action.payload;
			const messageIndex = state.messages.findIndex(msg => msg.id === id);
			if (messageIndex !== -1) {
				state.messages[messageIndex] = {
					...state.messages[messageIndex],
					message,
					type: type || state.messages[messageIndex].type
				};
			}
		},
		removeMessage: (state, action) => {
			const { id } = action.payload;
			state.messages = state.messages.filter(msg => msg.id !== id);
			if (state.messages.length === 0) {
				state.open = false;
			}
		}
	}
});

export const selectSnackBar = state => state.snackBar;

export const { display, hide, updateMessage, removeMessage } = slice.actions;

export default slice.reducer;
