/* eslint-disable prefer-destructuring */
/**
 * Main application source
 */
import "./style.css";
import { v4 as uuidv4 } from "uuid";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Icon, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import i18n from "i18n";
import lod_ from "lodash";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import ConfirmDialog from "components/Custom/Dialogs/ConfirmDialog";
import AddSourceDialog from "./AddSourceDialog";
import ChartsActions from "redux-react/actions/chartsActions";
import FaiqActions from "redux-react/actions/faiqActions";
import SourceActions from "redux-react/actions/sourceActions";
import KnowledgeActions from "redux-react/actions/knowledgeActions";
import { display, updateMessage, removeMessage } from "redux-react/reducers/snackBarReducer";
import MDTypography from "components/Basics/MDTypography";
import { addNotification } from "redux-react/reducers/notificationsReducer";
import { socket } from "redux-react/middleware/ws";
import ProcessSourceDialog from "./ProcessSourceDialog";
import MDBadge from "components/Basics/MDBadge";
import { t } from "i18next";
import MetadatasDialog from "./components/MetadatasDialog";
/**
 * Main component
 */
export default function SourcesPage({ route }) {
	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();
	// Main chart
	const [rawCharts, setRawCharts] = useState({});
	const [pageFilters, setPageFilters] = useState([]);
	const [paginedList, setPaginedList] = useState(null);

	const [reloadTable, setReloadTable] = useState(false);
	// Add new source dialog
	const [addNewSource, setAddNewSource] = useState(false);
	// Process source dialog
	const [processSource, setProcessSource] = useState({ open: false, source: {} });
	// Delete confirm dialog
	const [confirmDialog, setConfirmDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});

	// Confirm extract again
	const [confirmExtractDialog, setConfirmExtractDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});

	const [metadatasDialog, setMetadatasDialog] = useState({
		open: false,
		sources: []
	});

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});

	const [selectedRows, setSelectedRows] = useState([]);
	// Actions menu
	const [actionsAnchorEl, setActionsAnchorEl] = useState(null);

	const [temporaryID, setTemporaryID] = useState(null);

	/* Load chart */
	function loadPaginedList() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts(res.charts);
			setReloadTable(!reloadTable);
		};

		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));
	}
	/* Build charts */
	function buildRawCharts() {
		if (lod_.isEmpty(rawCharts)) return;
		// Success handler : set charts data
		const onSuccess = res => {
			setPaginedList(res.charts[0]);
		};
		// Build charts
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		dispatch(
			ChartsActions.buildRawCharts(
				rawCharts,
				getLocalStorageBackValues(profile.selectedAssistant.assistantID, route.route, filters),
				mandatoryFilters,
				onSuccess
			)
		);
	}

	/**
	 * Delete many sources
	 * @param {*} sources - List of sources code to delete
	 */
	const actionDeleteManySources = sources => {
		const handleConfirmDialog = async () => {
			setConfirmDialog({
				open: false
			});

			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SOURCES.DELETE.CALLS.success"),
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
			};
			// Delete source in database
			dispatch(SourceActions.deleteSources(sources, onSuccess));
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} ${i18n.t("SOURCES.theSources")}`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} ${i18n.t("SOURCES.theSources")} ?`,
			handleConfirm: handleConfirmDialog
		});
	};

	/**
	 * Start sources process pipeline
	 * - Extract text
	 * - Extract metadatas
	 * - Generate summary
	 * - Create knowledges
	 * @param {Array<string>} sources - List of sources code to process
	 */
	function startSourcesProcessPipeline(sources = []) {
		// Add a notification
		dispatch(
			addNotification({
				assistantID: profile.assistantID,
				type: "info",
				message: t("SOURCES.PIPELINE.pipelineInProgress"),
				ts: new Date().getTime()
			})
		);

		dispatch(
			FaiqActions.startFAIQPipeline(
				sources,
				["extractText", "metadatas", "summary", "knowledges"],
				() => {}
			)
		);
	}

	function trainAllKnowledgesFromSource(sources = []) {
		const id = uuidv4();
		setTemporaryID(id);

		dispatch(KnowledgeActions.trainAllKnowledgesFromSources(sources, () => {}));

		// Display a message to the user
		dispatch(
			display({
				id,
				message: `Entrainement en préparation...`,
				type: "loading"
			})
		);
	}

	// Ws progress from knowledge training
	function knowledgeTrainProgress({ current, total }) {
		const percentage = Math.round((current / total) * 100);

		// Update the message snackbar
		dispatch(
			updateMessage({
				id: temporaryID,
				message: t("KNOWLEDGE.TRAIN.trainProgress", { percentage })
			})
		);
	}

	// Ws result from knowledge training
	function knowledgeTrainResult({ success, message }) {
		let translatedtext = i18n.exists(`WS.MESSAGE.${message}`)
			? i18n.t(`WS.MESSAGE.${message}`)
			: message;

		// Update the message snackbar
		dispatch(
			updateMessage({
				id: temporaryID,
				message: translatedtext,
				type: success ? "success" : "error"
			})
		);

		// Add a notification
		dispatch(
			addNotification({
				assistantID: profile.assistantID,
				type: success ? "success" : "error",
				message: translatedtext,
				ts: new Date().getTime(),
				icon: success ? "check" : "close"
			})
		);

		setReloadTable(prev => !prev);
	}

	// WS event
	function faiqPipelineResult(result) {
		const success = result.success;
		const message = result.message;
		const steps = result.steps ?? [];

		let translatedtext = "";

		if (!success) {
			translatedtext = i18n.exists(`WS.MESSAGE.${message}`) ? t(`WS.MESSAGE.${message}`) : message;
		} else {
			if (steps.includes("metadatas")) {
				translatedtext += ` Les métadonnées ont été générées.`;
			}

			if (steps.includes("summary")) {
				translatedtext += ` Le résumé a été généré.`;
			}

			if (steps.includes("knowledges")) {
				translatedtext += ` Les connaissances ont été générées.`;
			}
		}

		dispatch(
			addNotification({
				assistantID: profile.assistantID,
				type: success ? "success" : "error",
				message: translatedtext.trim(),
				ts: new Date().getTime(),
				icon: success ? "check" : "close"
			})
		);

		setReloadTable(prev => !prev);
	}

	function wsProcessSourceEnd({ success, message }) {
		let translatedtext = i18n.exists(`WS.MESSAGE.${message}`)
			? i18n.t(`WS.MESSAGE.${message}`)
			: message;

		dispatch(
			addNotification({
				assistantID: profile.assistantID,
				type: success ? "success" : "error",
				message: translatedtext,
				ts: new Date().getTime(),
				icon: success ? "check" : "close"
			})
		);

		setReloadTable(prev => !prev);
	}

	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	useEffect(() => {
		socket.on("faiq_pipeline_result", faiqPipelineResult);
		socket.on("process_source_result", wsProcessSourceEnd);

		socket.on("knowledge_train_update", knowledgeTrainProgress);
		socket.on("knowledge_train_result", knowledgeTrainResult);

		return () => {
			socket.off("faiq_pipeline_result", faiqPipelineResult);
			socket.off("process_source_result", wsProcessSourceEnd);

			socket.off("knowledge_train_update", knowledgeTrainProgress);
			socket.off("knowledge_train_result", knowledgeTrainResult);
			// delete message if we have temporaryID
			if (temporaryID) {
				dispatch(
					removeMessage({
						id: temporaryID
					})
				);
			}
		};
	}, [temporaryID]);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox
						display="flex"
						justifyContent="space-between"
						style={{
							width: "100%"
						}}
					>
						{/* First container */}
						<MDBox display="flex" flex="1">
							{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
							{/* Buttons */}
							<MDBox
								flex="1"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								flexDirection="row"
							>
								{/* Left */}
								<MDBox>
									<MDButton
										style={{ height: "100%", marginRight: "0.75rem" }}
										variant="contained"
										color="info"
										onClick={() => {
											if (!paginedList?.dictionary?.metadatas) {
												dispatch(
													display({
														type: "error",
														message: "Aucune source de donnée n'est disponible"
													})
												);
											} else {
												setAddNewSource(true);
											}
										}}
									>
										<Icon>create</Icon>&nbsp;{i18n.t("SETTINGS.add")}
									</MDButton>
								</MDBox>
								{/* Right */}
								<MDBox>
									<MDBadge color="error" badgeContent={selectedRows.length} circular size="xs">
										<MDButton
											color="info"
											onClick={e => {
												// setAnchorEl(e.currentTarget);
												setActionsAnchorEl(e.currentTarget);
											}}
											disabled={selectedRows.length === 0}
										>
											<Icon>list_alt</Icon>&nbsp;Actions
										</MDButton>

										<Menu
											id="simple-actions-menu"
											anchorEl={actionsAnchorEl}
											open={Boolean(actionsAnchorEl)}
											onClose={e => {
												setActionsAnchorEl(null);
												e.stopPropagation();
												e.preventDefault();
											}}
										>
											<MenuItem disabled style={{ opacity: 1 }}>
												<MDTypography variant="caption">
													{i18n.t(`FAIQ.TESTING.actions`)}
												</MDTypography>
											</MenuItem>
											{/* Extract */}
											<MenuItem
												disabled={selectedRows.length < 1}
												onClick={() => {
													const codeList = selectedRows.map(row => row.code);
													startSourcesProcessPipeline(codeList);

													setActionsAnchorEl(null);
													setSelectedRows([]);
												}}
											>
												<Icon fontSize="medium">text_snippet</Icon>&nbsp;
												{t("SOURCES.ACTIONS.createKnowledges")}
											</MenuItem>
											{/* Metadatas */}
											<MenuItem
												disabled={selectedRows.filter(row => row.extracted).length < 1}
												onClick={() => {
													const codeList = selectedRows
														.filter(row => row.extracted)
														.map(row => row.code);

													setMetadatasDialog({
														open: true,
														sources: codeList
													});
													setActionsAnchorEl(null);
													setSelectedRows([]);
												}}
											>
												<Icon fontSize="medium">storage</Icon>&nbsp;
												{i18n.t("SOURCES.ACTIONS.extractMetadatas")}
											</MenuItem>
											{/*  Train */}
											<MenuItem
												disabled={
													selectedRows.filter(row => row.extracted && !row.trainedKnowledges)
														.length < 1
												}
												onClick={() => {
													const codeList = selectedRows
														.filter(row => row.extracted && !row.trainedKnowledges)
														.map(row => row.code);

													trainAllKnowledgesFromSource(codeList);

													setActionsAnchorEl(null);
													setSelectedRows([]);
												}}
											>
												<Icon fontSize="medium">model_training</Icon>&nbsp;
												{t("SOURCES.ACTIONS.trainKnowledges")}
											</MenuItem>
											{/*  Delete */}
											<Divider />
											<MenuItem
												onClick={() => {
													const sources = selectedRows.map(row => row.code);
													actionDeleteManySources(sources);
													setActionsAnchorEl(null);
													setSelectedRows([]);
												}}
												style={{
													color: "red"
												}}
											>
												<Icon fontSize="medium">delete</Icon>&nbsp;Supprimer
											</MenuItem>
										</Menu>
									</MDBadge>
								</MDBox>
							</MDBox>
						</MDBox>
					</MDBox>
				]}
			/>
			<Divider />
			{paginedList && (
				<DefaultDataTable
					route={route}
					checkbox
					selectedRows={selectedRows}
					handleSelection={values => {
						setSelectedRows(values);
					}}
					isSelectedRow={row => {
						const existingRow = selectedRows.find(r => r.code === row.code);
						return Boolean(existingRow);
					}}
					dictionary={paginedList.dictionary}
					canSearch
					table={paginedList.data}
					display={paginedList.request.attributesDisplay}
					pagination={paginedList.pagination}
					list={paginedList}
					optionsAction="left"
					actions={[
						<IconButton
							handleclick={({ values }, e) => {
								setMenuValues(values);
								setMenuRef(e.currentTarget);
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<Icon fontSize="medium">more_vert</Icon>
						</IconButton>
					]}
					reloadTable={reloadTable}
					filters={getLocalStorageBackValues(
						profile.selectedAssistant.assistantID,
						route.route,
						filters
					)}
				/>
			)}
			{/* Menu actions selection */}
			<Menu
				open={Boolean(menuRef)}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
				}}
			>
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.actions`)}</MDTypography>
				</MenuItem>
				{/* See */}
				<MenuItem component="a" href={menuValues["file.url"] || menuValues.url} target="_blank">
					<Icon fontSize="medium">visibility</Icon>&nbsp;{i18n.t("SETTINGS.see")}
				</MenuItem>
				{/* Extract */}
				<Divider />
				<MenuItem
					onClick={() => {
						setMenuRef(null);
						startSourcesProcessPipeline([menuValues.code]);
					}}
				>
					<Icon fontSize="medium">text_snippet</Icon>&nbsp;{t("SOURCES.ACTIONS.createKnowledges")}
				</MenuItem>
				{/* Configure */}
				<MenuItem
					disabled={!menuValues.extracted}
					onClick={() => {
						setProcessSource({
							open: true,
							source: menuValues
						});

						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">settings</Icon>&nbsp;{i18n.t("SETTINGS.configure")}
				</MenuItem>
				{/* Train */}
				<MenuItem
					disabled={menuValues.trainedKnowledges || !menuValues.extracted}
					onClick={() => {
						setMenuRef(null);
						trainAllKnowledgesFromSource([menuValues.code]);
					}}
				>
					<Icon fontSize="medium">model_training</Icon>&nbsp;{t("SOURCES.ACTIONS.trainKnowledges")}
				</MenuItem>
				{/*  */}
				<Divider />
				{/* Delete */}
				<MenuItem
					onClick={() => {
						actionDeleteManySources([menuValues.code]);
						setMenuRef(null);
					}}
					style={{
						color: "red"
					}}
				>
					<Icon fontSize="medium">delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
				</MenuItem>
			</Menu>
			{paginedList && addNewSource && (
				<AddSourceDialog
					metadatas={paginedList.dictionary.metadatas}
					open={addNewSource}
					handleCloseDialog={() => {
						setAddNewSource(false);
					}}
				/>
			)}
			{/* Process source dialog */}
			{paginedList && processSource.open && (
				<ProcessSourceDialog
					open={processSource.open}
					handleCloseDialog={() => {
						setProcessSource({ open: false });
					}}
					handleSave={() => {
						setReloadTable(!reloadTable);
					}}
					metadatasDictionary={paginedList.dictionary.metadatas}
					source={processSource.source}
				/>
			)}
			{/* Dialog to confirm delete */}
			<ConfirmDialog
				open={confirmDialog.open}
				title={confirmDialog.title}
				content={confirmDialog.content}
				handleClose={() => {
					setConfirmDialog({
						open: false
					});
				}}
				handleConfirm={confirmDialog.handleConfirm}
			/>
			{/* Dialog to confirm another extract */}
			<ConfirmDialog
				open={confirmExtractDialog.open}
				title={confirmExtractDialog.title}
				content={confirmExtractDialog.content}
				handleClose={() => {
					setConfirmExtractDialog({
						open: false
					});
				}}
				handleConfirm={confirmExtractDialog.handleConfirm}
			/>
			{/* Dialog to manage metadatas */}
			{paginedList && metadatasDialog.open && (
				<MetadatasDialog
					open={metadatasDialog.open}
					onClose={() => {
						setMetadatasDialog({
							open: false,
							sources: []
						});
					}}
					sources={metadatasDialog.sources}
					metadatasDictionary={paginedList.dictionary.metadatas}
				/>
			)}

			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
