/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-extraneous-dependencies */
import { Accordion, AccordionDetails, AccordionSummary, Box, Icon } from "@mui/material";
import lod_ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useRef } from "react";
import { ExpandMore } from "@mui/icons-material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import { t } from "i18next";
import { regexMail, regexPhoneNumber } from "helpers/utilities";
import MDTypography from "components/Basics/MDTypography";
import { uploadToSignedUrl, getSignedUrl } from "helpers/s3";
import { createDictionarySkeleton } from "helpers/form";
import ChildAccordionArrayMapper from "./ChildAccordionArrayMapper";
import InputType from "./InputType";

const useStyles = {
	numberCircle: {
		width: "30px",
		height: "30px",
		padding: "6px 0px",
		borderRadius: "15px",
		textAlign: "center",
		fontSize: "14px",
		lineHeight: 1.42857,
		backgroundColor: "#dfe6e9"
	}
};

function OrderFormDictionaryChild({
	contextDictionary,
	currentDictionary,
	fieldChild,
	values,
	userLangage,
	root,
	formDictionary,
	customErrors,
	PI,
	isEmpty,
	routeDict,
	regexEmail,
	listAPIs,
	setCustomErrors,
	setFormDictionary,
	handleInputChange,
	setHasErrors,
	setCustomUnique,
	customUnique,
	handleRemoveItemToArray,
	handleAddItemToArray
}) {
	const classes = useStyles;

	const scrollBottom = useRef(null);

	const checkInputRequired = async (valueInput, child) => {
		if (lod_.isNil(valueInput) || lod_.isEmpty(valueInput)) {
			setCustomErrors({ ...customErrors, [child]: t("FORMS.required") });
		}
	};

	const handleInputChangeValidate = async (e, value, child) => {
		// special handle for default value of dictionary

		if (child === "defaultValue" && routeDict === "dictionary") {
			value.type = e.defaultType;
			e = e.event;
		}

		if (value?.type === "boolean") {
			e = { target: { name: child, value: Boolean(e.target.value) } };

			handleInputChange(e);
			return;
		}

		if (value?.type === "email") {
			e = { target: { name: child, value: e.target.value.toLowerCase() } };
		}

		if (value?.isRequired && (lod_.isNil(e.target.value) || lod_.isEmpty(e.target.value))) {
			setCustomErrors({ ...customErrors, [child]: t("FORMS.required") });
			setHasErrors(true);
			handleInputChange(e);
			return;
		}

		if (child.includes(".value")) {
			child = child.replaceAll(".value", "");
			e.target.name = e.target.name.replaceAll(".value", "");
		}

		if (value?.blacklist) {
			if (value.blacklist.includes(e.target.value)) {
				setCustomErrors({ ...customErrors, [child]: t("FORMS.blackListCustomer") });
				setHasErrors(true);
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			}
		}

		if (value?.whitelist) {
			if (
				value.whitelist.includes(e.target.value) ||
				e.target.value === "" ||
				value.whitelist.length === 0
			) {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			} else {
				setCustomErrors({ ...customErrors, [child]: t("FORMS.whitelistContact") });
				setHasErrors(true);
			}
		}

		if (value?.unique) {
			if ((e?.target?.value ?? e) !== value.value) {
				setCustomUnique({ ...customUnique, [child]: e?.target?.value ?? e });
			} else {
				let copyUnique = lod_.cloneDeep(customUnique);
				delete copyUnique[child];
				setCustomUnique(copyUnique);
			}

			if (value?.type === "email" && !regexMail(e.target.value, regexEmail) && !value?.isArray) {
				setCustomErrors({
					...customErrors,
					[child]: t("FORMS.invalidEmail")
				});
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
			}
		} else if (
			value?.type === "email" &&
			!value?.isArray &&
			!regexMail(e.target.value, regexEmail)
		) {
			setCustomErrors({
				...customErrors,
				[child]: t("FORMS.invalidEmail")
			});
		} else {
			let copyErrors = lod_.cloneDeep(customErrors);
			delete copyErrors[child];
			setCustomErrors(copyErrors);
		}

		if (value?.type === "mdp") {
			const password = e.target.value;

			const AtLeast8chars = password.length > 7;

			const AtLeastOneLower = /[a-z]/.test(password);

			const AtLeastOneUpper = /[A-Z]/.test(password);

			const AtLeastOneNumber = /[0-9]/.test(password);

			const AtLeastOneSpecial = /[-+_!@#$%^&*.,?]/.test(password);

			const passwordIsStrong =
				AtLeast8chars &&
				AtLeastOneLower &&
				AtLeastOneUpper &&
				AtLeastOneNumber &&
				AtLeastOneSpecial;

			const lengthValue = t("FORMS.psswordStrength.length");
			const oneLower = t("FORMS.psswordStrength.lowercaseLetter");
			const oneUpper = t("FORMS.psswordStrength.capitalLetter");
			const oneNumber = t("FORMS.psswordStrength.number");
			const oneSpecial = t("FORMS.psswordStrength.specialChar");

			const validateMessage = (
				<MDBox borderRadius="lg" p={3}>
					<MDBox>
						<MDTypography display="inline" variant="h6" fontWeight="bold">
							{t("FORMS.psswordStrength.message")}
						</MDTypography>
						<MDBox>
							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeast8chars ? "red" : "green" }}
							>
								{lengthValue} {!AtLeast8chars ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>

							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeastOneLower ? "red" : "green" }}
							>
								{oneLower} {!AtLeastOneLower ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>

							<MDTypography
								variant="body2"
								component="p"
								style={{ color: !AtLeastOneUpper ? "red" : "green" }}
								alignItems="center"
								display="flex"
							>
								{oneUpper} {!AtLeastOneUpper ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>

							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeastOneNumber ? "red" : "green" }}
							>
								{oneNumber} {!AtLeastOneNumber ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>
							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeastOneSpecial ? "red" : "green" }}
							>
								{oneSpecial} {!AtLeastOneSpecial ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			);

			if (!passwordIsStrong) {
				setCustomErrors({ ...customErrors, [child]: validateMessage });
				setHasErrors(true);
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			}
		}

		if (value?.type === "date") {
			e = { target: { name: child, value: new Date(e[0]) } };
		}

		if (value?.type === "number") {
			if (!value?.isArray) {
				e = { target: { name: child, value: parseFloat(e.target.value) } };
			}
		}

		if (value?.type === "picture") {
			if (e.type === "change") {
				if (e.target.files[0].type.startsWith("image/")) {
					const { success, error, result } = await await getSignedUrl(e.target.files[0]);

					if (!success) {
						return;
					}

					const signedUrl = result.signedUrl;
					const fileObject = result.fileObject;

					await uploadToSignedUrl(signedUrl, e.target.files[0]);
					e = {
						/**
						 * FIXME - We only save the S3 URL in the dictionary, so we can't remove the file from S3
						 * See the FIXME below
						 */
						target: {
							name: child,
							value: fileObject.url
						}
					};
					let copyErrors = lod_.cloneDeep(customErrors);
					delete copyErrors[child];
					setCustomErrors(copyErrors);
				} else {
					setCustomErrors({
						...customErrors,
						[child]: t("FORMS.invalidPicture")
					});
					e = { target: { name: child, value: "" } };
				}
			} else {
				/**
				 * FIXME - At this point we dont have the file object, so we can't remove it from S3
				 * be cause the only thing we have is the URL on cm2
				 * -> Need to change the storage of "picture" element in dictionary to save more datas !
				 */

				// deleteFileFromPath({ key: ...})

				/**
				 * FIXME - Deprecated function, use function above
				 */
				// await removeToSignedUrl(values[child]);

				e = { target: { name: child, value: "" } };
			}
		}

		if (value?.type === "phone") {
			e = { target: { name: child, value: e } };
			e.target.value = e.target.value.replace(/\s/g, "");
			if (!regexPhoneNumber(e.target.value) && !lod_.isEmpty(e.target.value)) {
				setCustomErrors({ ...customErrors, [child]: t("FORMS.invalidPhone") });
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
			}
		}

		handleInputChange(e);
	};

	function mapFrontLogicItem(item) {
		let valueObject = {};

		if (item.type !== "level") {
			// Element required
			let { label, type, display, isArray } = item;
			let editArray = item?.actions?.edit || true;

			let pendingUUID = uuidv4();

			valueObject = {
				value: "",
				type,
				isEdit: editArray,
				...label,
				isArray,
				display
			};

			// Element secondary
			let { order, codes, defaultValue, arrayValue } = item;
			if (!lod_.isNil(order)) {
				valueObject.order = order;
			}
			if (codes) {
				valueObject.codes = codes;
			}
			if (defaultValue) {
				valueObject.defaultValue = defaultValue;
				valueObject.value = defaultValue;
			}
			if (arrayValue) {
				valueObject.arrayValue = arrayValue;
			}
			if (type === "uuid") {
				valueObject.value = pendingUUID;
			}
		}

		return valueObject;
	}

	const addItemToArray = rootPath => {
		let dictionaryLevel = lod_.get(contextDictionary, rootPath.replaceAll(".", ".items."));
		// Empty level for back logic
		let emptyLevel = createDictionarySkeleton(dictionaryLevel.items, {});
		let emptyFrontLevel = {};

		// Empty level for front logic

		let dictionaryLevelKeys = Object.keys(dictionaryLevel.items);
		for (let key of dictionaryLevelKeys) {
			let item = dictionaryLevel.items[key];
			let valueObject = mapFrontLogicItem(item);
			let { value } = valueObject;
			// If we have a value, set it for back logic (used for UUID)
			if (value) {
				lod_.set(emptyLevel, key, value);
			}
			emptyFrontLevel[key] = valueObject;
		}
		handleAddItemToArray(rootPath, emptyLevel, emptyFrontLevel);
		scrollBottom.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<>
			{Object.keys(fieldChild)
				.sort((a, b) => fieldChild[a].order - fieldChild[b].order)
				.map((child, key) => {
					let rootPath = root === "" ? `${child}` : root + `.${child}`;
					if (fieldChild[child].type !== "level" && typeof fieldChild[child] === "object") {
						let formConfig = lod_.get(formDictionary, rootPath);
						if (Array.isArray(fieldChild[child])) {
							return null;
						} else {
							return (
								<Box key={key}>
									<InputType
										regexEmail={regexEmail}
										listAPIs={listAPIs}
										child={rootPath}
										whitelistDynamic={formConfig?.whitelistDynamic}
										isArray={formConfig?.isArray}
										whitelist={formConfig?.whitelist}
										isDisplay={formConfig?.display}
										codes={formConfig?.codes}
										isRequired={fieldChild[child]?.isRequired}
										label={fieldChild[child][userLangage]}
										isEdit={fieldChild[child]?.isEdit}
										defaultValueForItem={fieldChild[child]?.defaultValue}
										key={key}
										PI={PI}
										isEmpty={isEmpty}
										type={formConfig?.type}
										valueForm={values}
										value={lod_.get(values, rootPath)}
										customErrors={lod_.get(customErrors, rootPath)}
										onChange={e => handleInputChangeValidate(e, formConfig, rootPath)}
										values={values}
										routeDict={routeDict}
										checkRequired={valueInput => checkInputRequired(valueInput, rootPath)}
									/>
								</Box>
							);
						}
					}

					if (
						fieldChild[child].type === "level" &&
						typeof fieldChild[child] === "object" &&
						!fieldChild[child].isArray
					) {
						let label = currentDictionary[child].label[userLangage];
						return (
							<Accordion
								className="customAccordion"
								sx={{ mb: 1 }}
								style={{ boxShadow: "none" }}
								key={key}
							>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									{label}
								</AccordionSummary>
								<AccordionDetails>
									<OrderFormDictionaryChild
										fieldChild={fieldChild[child]}
										isEmpty={isEmpty}
										userLangage={userLangage}
										root={rootPath}
										values={values}
										key={key}
										PI={PI}
										formDictionary={formDictionary}
										customErrors={customErrors}
										contextDictionary={contextDictionary}
										currentDictionary={currentDictionary[child].items}
										routeDict={routeDict}
										setCustomErrors={setCustomErrors}
										setFormDictionary={setFormDictionary}
										handleInputChange={handleInputChange}
										setHasErrors={setHasErrors}
										setCustomUnique={setCustomUnique}
										customUnique={customUnique}
										handleRemoveItemToArray={handleRemoveItemToArray}
										handleAddItemToArray={handleAddItemToArray}
									/>
								</AccordionDetails>
							</Accordion>
						);
					} else if (fieldChild[child].type === "level" && fieldChild[child].isArray) {
						let label = contextDictionary[child].label[userLangage];
						return (
							<Accordion style={{ margin: "0" }} className="customAccordion" key={key}>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									{label}
								</AccordionSummary>
								<AccordionDetails>
									<MDBox mb={1}>
										<MDButton
											size="small"
											style={{ height: "100%", marginRight: "0.75rem" }}
											variant="contained"
											color="info"
											onClick={() => addItemToArray(rootPath)}
											disabled={!PI}
										>
											<Icon>add</Icon>&nbsp;{t("FORMS.addProduct")}
										</MDButton>
									</MDBox>
									{fieldChild[child].value.map((childArray, key) => {
										let rootArray = rootPath + `[${key}]`;

										let rootArrayDico = rootPath + `.value[${key}]`;

										return (
											<ChildAccordionArrayMapper
												path={rootPath}
												fieldChild={fieldChild[child].value[key]}
												index={key}
												contextDictionary={contextDictionary}
												isEmpty={isEmpty}
												title={fieldChild[child][userLangage]}
												classes={classes}
												values={values}
												root={rootArray}
												rootPath={rootArrayDico}
												PI={PI}
												formDictionary={formDictionary}
												userLangage={userLangage}
												customErrors={customErrors}
												handleRemoveItemToArray={handleRemoveItemToArray}
												handleAddItemToArray={addItemToArray}
												handleInputChangeValidate={handleInputChangeValidate}
												checkInputRequired={checkInputRequired}
												routeDict={routeDict}
												key={key}
											/>
										);
									})}
									<div ref={scrollBottom}></div>
								</AccordionDetails>
							</Accordion>
						);
					}

					return null;
				})}
		</>
	);
}

export default OrderFormDictionaryChild;
