/* eslint-disable no-extra-boolean-cast */
import { Switch } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

const InputBoolean = ({
	label,
	value,
	onChange,
	disabled,
	error,
	item,
	InputProps = {},
	...rest
}) => {
	return (
		<MDBox
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
			onClick={e => {
				if (!disabled) {
					onChange(!Boolean(value));
				}
			}}
			{...InputProps}
			bgColor={disabled ? "light" : "white"}
		>
			<MDBox>
				<span>{label}</span>
				{item.description && (
					<MDTypography variant="body2" fontSize="small">
						{item?.description || ""}
					</MDTypography>
				)}
			</MDBox>
			<MDBox>
				<Switch disabled={disabled} checked={Boolean(value)} />
			</MDBox>
		</MDBox>
	);
};
export default InputBoolean;
