/* eslint-disable no-restricted-syntax */
import lod_ from "lodash";
import { Dialog, DialogActions, DialogContent, DialogTitle, Fade } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import InformationCard from "pages/settings/charts/steps/components/InformationCard";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FaiqActions from "redux-react/actions/faiqActions";
import SourceActions from "redux-react/actions/sourceActions";
import { addNotification } from "redux-react/reducers/notificationsReducer";
import { t } from "i18next";
import { createDictionarySkeleton } from "helpers/form";
import MDTypography from "components/Basics/MDTypography";
import { getAllPaths, getLabelPath } from "helpers/dictionary";
import { display } from "redux-react/reducers/snackBarReducer";
import DictionaryButton from "components/Custom/DictionaryButton";
import { FormInput } from "components/Custom/LittleForm";

/**
 * First step of the dialog
 * @param {*} param0
 * @param {function} param0.onClose - Function to close the dialog
 * @param {Array} param0.sources - Sources to manage
 * @param {function} param0.setStep - Function to set the step
 * @returns {JSX.Element} - The step
 */
const FirstStep = ({ onClose, sources, setStep }) => {
	const { profile } = useSelector(state => state);
	const dispatch = useDispatch();

	const generateMetadatas = () => {
		// Add a notification
		dispatch(
			addNotification({
				assistantID: profile.assistantID,
				type: "info",
				message: t("SOURCES.METADATAS.generationProgress"),
				ts: new Date().getTime()
			})
		);

		// Start the pipeline
		dispatch(FaiqActions.startFAIQPipeline(sources, ["metadatas"], () => {}));
		onClose();
	};

	return (
		<MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
			<MDBox
				style={{
					width: "100%"
				}}
			>
				<InformationCard
					checkbox
					element={{
						title: t("SOURCES.METADATAS.autoExtractTitle"),
						description: t("SOURCES.METADATAS.autoExtractDescription", { count: sources.length })
					}}
					onClick={generateMetadatas}
				/>
			</MDBox>
			<MDBox
				style={{
					width: "100%"
				}}
			>
				<InformationCard
					checkbox
					element={{
						title: t("SOURCES.METADATAS.selfExtractTitle"),
						description: t("SOURCES.METADATAS.selfExtractDescription", { count: sources.length })
					}}
					onClick={() => setStep(1)}
				/>
			</MDBox>
		</MDBox>
	);
};

/**
 * Step to edit metadatas
 * @param {*} param0
 * @param {Object} param0.metadatasDictionary - Metadatas dictionary
 * @param {Object} param0.skeleton - Skeleton of the dictionary
 * @param {function} param0.handleChange - Function to handle the change of a value
 * @returns {JSX.Element} - The step
 */
const SecondStep = ({
	metadatasDictionary = {},
	skeleton = {},
	setSkeleton,
	handleChange = () => {}
}) => {
	const [dictionary, setDictionary] = useState({});
	const [selectedItem, setSelectedItem] = useState(null);

	useEffect(() => {
		let clonedDictionary = lod_.cloneDeep(metadatasDictionary);

		let items = {};

		for (let key of Object.keys(clonedDictionary.items ?? {})) {
			let item = clonedDictionary.items[key];
			if (!item.system) {
				items[key] = item;
			}
		}

		clonedDictionary.items = items;
		setDictionary(clonedDictionary);
	}, [metadatasDictionary]);

	return (
		<MDBox>
			<MDBox flex="2">
				<MDTypography variant="h6">{t("SOURCES.METADATAS.selfExtractTitle")}</MDTypography>
				<MDBox mt={2}>
					{!lod_.isEmpty(dictionary) && (
						<MDBox display="flex" alignItems="center">
							<DictionaryButton
								dictionary={dictionary.items}
								handleClick={e => {
									if (selectedItem) {
										setSkeleton({});
									}
									setSelectedItem(e);
									handleChange(e, []);
								}}
							/>
							<MDTypography variant="body2" color="textSecondary">
								{getLabelPath(dictionary.items, selectedItem, t("SOURCES.ACTIONS.selectMetadatas"))}
							</MDTypography>
						</MDBox>
					)}
					{selectedItem && (
						<MDBox mt={2}>
							<FormInput
								item={lod_.get(dictionary.items, selectedItem)}
								datas={skeleton}
								handleChange={handleChange}
								path={selectedItem}
								errorUniqueFields={[]}
							/>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

/**
 * Metadatas dialog
 * @param {*} param0
 * @param {boolean} param0.open - Open state of the dialog
 * @param {function} param0.onClose - Function to close the dialog
 * @param {Array} param0.sources - Sources to manage
 * @param {Object} param0.metadatasDictionary - Metadatas dictionary
 * @returns {JSX.Element} - The dialog
 */
const MetadatasDialog = ({ open, onClose, sources = [], metadatasDictionary }) => {
	const dispatch = useDispatch();

	const [step, setStep] = useState(0);
	const [skeleton, setSkeleton] = useState({});

	/**
	 * Handle the change of a value in the skeleton
	 * @param {string} path - Path of the value
	 * @param {any} value - New value
	 */
	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(skeleton);
		lod_.set(clone, path, value);
		setSkeleton(clone);
	};

	/**
	 * Get the content of the dialog
	 * @returns {JSX.Element} - The content of the dialog
	 */
	const getContent = () => {
		switch (step) {
			case 0:
				return <FirstStep onClose={onClose} sources={sources} setStep={setStep} />;
			case 1:
				return (
					<SecondStep
						onClose={onClose}
						sources={sources}
						metadatasDictionary={metadatasDictionary}
						skeleton={skeleton}
						setSkeleton={setSkeleton}
						handleChange={handleChange}
					/>
				);
			default:
				return null;
		}
	};

	/**
	 * Update all sources with the new metadatas
	 * @param {object} update - Update object
	 */
	const updateSources = update => {
		dispatch(
			SourceActions.updateSources(sources, update, () => {
				dispatch(
					display({
						type: "info",
						message: t("SOURCES.METADATAS.CALLS.updateSuccess")
					})
				);
				onClose();
			})
		);
	};

	useEffect(() => {
		setStep(0);
		setSkeleton({});
	}, []);

	if (lod_.isEmpty(sources)) {
		return null;
	}

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			{/* <DialogTitle>Gestion des métadonnées</DialogTitle> */}
			<DialogContent>{getContent()}</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					{t("SETTINGS.close")}
				</MDButton>
				{step === 1 && (
					<Fade in={step === 1}>
						<MDButton
							variant="contained"
							color="info"
							onClick={() => {
								updateSources({ metadatas: skeleton });
							}}
						>
							{t("SETTINGS.save")}
						</MDButton>
					</Fade>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default MetadatasDialog;
