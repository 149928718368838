import MDBox from "components/Basics/MDBox";
import { useDispatch, useSelector } from "react-redux";
import {
	Card,
	CardContent,
	Dialog,
	Fab,
	Fade,
	FormControl,
	FormControlLabel,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Slider,
	Switch
} from "@mui/material";
import lod_ from "lodash";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import MDButton from "components/Basics/MDButton";
import { CustomFlag } from "utilities";
import i18n from "i18n";
import FaiqActions from "redux-react/actions/faiqActions";
import { Save } from "@mui/icons-material";
import {
	setEnableRouting,
	setDisableRouting,
	changeRouteHandle
} from "redux-react/reducers/applicationReducer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 */
const LanguageSelection = ({ path, index, languages, settings, setSettings }) => {
	/**
	 * Change a key in a list of object : change "EN" to "FR"
	 *  { EN: "Bonjour", DE: "Guten tag"} => {FR: "Bonjour", DE: "Guten tag"}
	 */
	const changeKeyNameOfObject = (obj, oldKey, newKey) => {
		let oldValue = obj[oldKey];
		let oldIndex = Object.keys(obj).indexOf(oldKey);

		let buildedRow = [newKey, oldValue];

		let toArray = Object.entries(obj);
		toArray.splice(oldIndex, 1, buildedRow);

		let newObject = Object.fromEntries(toArray);

		return newObject;
	};
	// Update a language line in object
	const updateLanguageLine = (path, oldKey, newKey) => {
		let newSettings = lod_.cloneDeep(settings);
		let languages = lod_.get(newSettings, path);

		let newLanguages = changeKeyNameOfObject(languages, oldKey, newKey);
		lod_.set(newSettings, path, newLanguages);

		setSettings(newSettings);
	};
	// Return available languages that are not in the object
	const availableLanguages = (path, actual) => {
		let objLanguages = lod_.get(settings, path);
		objLanguages = Object.keys(objLanguages);
		objLanguages = objLanguages.filter(language => language !== actual);
		return lod_.difference(languages, objLanguages);
	};

	return (
		<FormControl>
			<InputLabel id="select-type"></InputLabel>
			<Select
				className="customSelect"
				labelId="select-type"
				value={index}
				onChange={e => updateLanguageLine(path, index, e.target.value)}
			>
				{availableLanguages(path, index).map((lang, index) => (
					<MenuItem key={index} value={lang}>
						<CustomFlag
							code={lang}
							style={{
								heigth: "2vh",
								width: "2vh"
							}}
						/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const AdvancedSettings = ({ settings, onChange }) => {
	const permissions = useSelector(state => state.permissions?.permissions);

	if (!permissions.includes("exec:faiqSettingsAdvancedSettings")) {
		return null;
	}

	return (
		<MDBox mt={3}>
			<MDBox mt={3} mb={3}>
				<MDTypography variant="h3">Paramètres avancés</MDTypography>
			</MDBox>

			<MDBox
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(3, 1fr)",
					gap: "10px"
				}}
				mt={3}
			>
				<Card
					style={{
						height: "100%"
					}}
				>
					<CardContent>
						{/*
						 * Minimum distance language
						 */}
						<MDBox mt={2}>
							<MDBox display="flex" justifyContent="start" flexDirection="column">
								<MDBox display="flex" justifyContent="space-between" alignItems="center">
									<MDTypography variant="h6">Detection de language</MDTypography>
									<MDTypography variant="caption" fontSize="small" fontWeight="bold">
										{`${Math.round((settings.limits?.minimumDistanceLanguage || 0.95) * 100)}%`}
									</MDTypography>
								</MDBox>

								<MDTypography variant="caption" fontSize="small">
									Score minimum
								</MDTypography>
							</MDBox>
							<MDBox>
								<MDBox mt={1} display="flex" alignItems="start">
									<Slider
										value={settings.limits?.minimumDistanceLanguage || 0.95}
										marks={[
											{
												value: 0,
												label: "0"
											},
											{
												value: 0.5,
												label: "50"
											},
											{
												value: 1,
												label: "100"
											}
										]}
										min={0}
										max={1}
										step={0.05}
										onChange={(e, value) => {
											onChange(`limits.minimumDistanceLanguage`, value);
										}}
									/>
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>

				<Card
					style={{
						height: "100%"
					}}
				>
					<CardContent>
						{/*
						 * Minimum distance vector question
						 */}
						<MDBox mt={2}>
							<MDBox display="flex" justifyContent="start" flexDirection="column">
								<MDBox display="flex" justifyContent="space-between" alignItems="center">
									<MDTypography variant="h6">Recherche de question</MDTypography>
									<MDTypography variant="caption" fontSize="small" fontWeight="bold">
										{`${Math.round((settings.limits?.minimumDistanceVectorQuestion || 0.9) * 100)}%`}
									</MDTypography>
								</MDBox>

								<MDTypography variant="caption" fontSize="small">
									Score minimum
								</MDTypography>
							</MDBox>
							<MDBox>
								<MDBox mt={1} display="flex" alignItems="start">
									<Slider
										value={settings.limits?.minimumDistanceVectorQuestion || 0.9}
										marks={[
											{
												value: 0,
												label: "0"
											},
											{
												value: 0.5,
												label: "50"
											},
											{
												value: 1,
												label: "100"
											}
										]}
										min={0}
										max={1}
										step={0.05}
										onChange={(e, value) => {
											onChange(`limits.minimumDistanceVectorQuestion`, value);
										}}
									/>
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>

				<Card
					style={{
						height: "100%"
					}}
				>
					<CardContent>
						{/*
						 * Minimum distance vector summary
						 */}
						<MDBox mt={2}>
							<MDBox display="flex" justifyContent="start" flexDirection="column">
								<MDBox display="flex" justifyContent="space-between" alignItems="center">
									<MDTypography variant="h6">Recherche de résumé</MDTypography>
									<MDTypography variant="caption" fontSize="small" fontWeight="bold">
										{`${Math.round((settings.limits?.minimumDistanceVectorSummary || 0.85) * 100)}%`}
									</MDTypography>
								</MDBox>

								<MDTypography variant="caption" fontSize="small">
									Score minimum
								</MDTypography>
							</MDBox>
							<MDBox>
								<MDBox mt={1} display="flex" alignItems="start">
									<Slider
										value={settings.limits?.minimumDistanceVectorSummary || 0.9}
										marks={[
											{
												value: 0,
												label: "0"
											},
											{
												value: 0.5,
												label: "50"
											},
											{
												value: 1,
												label: "100"
											}
										]}
										min={0}
										max={1}
										step={0.05}
										onChange={(e, value) => {
											onChange(`limits.minimumDistanceVectorSummary`, value);
										}}
									/>
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>

				<Card
					style={{
						height: "100%"
					}}
				>
					<CardContent>
						{/*
						 * Minimum distance vector knowledge
						 */}
						<MDBox mt={2}>
							<MDBox display="flex" justifyContent="start" flexDirection="column">
								<MDBox display="flex" justifyContent="space-between" alignItems="center">
									<MDTypography variant="h6">Recherche de connaissances</MDTypography>
									<MDTypography variant="caption" fontSize="small" fontWeight="bold">
										{`${Math.round((settings.limits?.minimumDistanceVectorKnowledge || 0.8) * 100)}%`}
									</MDTypography>
								</MDBox>

								<MDTypography variant="caption" fontSize="small">
									Score minimum
								</MDTypography>
							</MDBox>
							<MDBox>
								<MDBox mt={1} display="flex" alignItems="start">
									<Slider
										value={settings.limits?.minimumDistanceVectorKnowledge || 0.8}
										marks={[
											{
												value: 0,
												label: "0"
											},
											{
												value: 0.5,
												label: "50"
											},
											{
												value: 1,
												label: "100"
											}
										]}
										min={0}
										max={1}
										step={0.05}
										onChange={(e, value) => {
											onChange(`limits.minimumDistanceVectorKnowledge`, value);
										}}
									/>
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>

				<Card
					style={{
						height: "100%"
					}}
				>
					<CardContent>
						{/*
						 * Default question language
						 */}
						<MDBox mt={2}>
							<MDTypography variant="h6">Langue de question par défaut</MDTypography>
							<MDBox>
								<MDBox mt={1} display="flex" alignItems="start">
									<MDInput
										fullWidth
										value={
											settings.limits?.defaultQuestionLanguage === undefined
												? "FR"
												: settings.limits?.defaultQuestionLanguage
										}
										onChange={e =>
											onChange(`limits.defaultQuestionLanguage`, e.target?.value?.toUpperCase())
										}
									/>
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>

				<Card
					style={{
						height: "100%"
					}}
				>
					<CardContent
						style={{
							height: "100%"
						}}
					>
						{/*
						 * Default question language
						 */}
						<MDBox
							mt={2}
							display="flex"
							flexDirection="column"
							style={{
								height: "100%"
							}}
						>
							<MDBox display="flex" justifyContent="start" flexDirection="column">
								<MDTypography variant="h6">Dection de langue à chaque message</MDTypography>
								<MDTypography variant="caption" fontSize="small">
									Activé / Désactivé
								</MDTypography>
							</MDBox>
							<MDBox flex="1" display="flex" alignItems="center" justifyContent="end">
								<Switch
									checked={settings.limits?.analyseEachMessageLanguage === true}
									onChange={e =>
										onChange(
											`limits.analyseEachMessageLanguage`,
											!settings.limits?.analyseEachMessageLanguage
										)
									}
								/>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>
			</MDBox>
		</MDBox>
	);
};

/*
 * Main component
 * Settings part of the settings page
 */
const SettingsPart = ({ route, copySetting, setCopySetting, settings, setSettings }) => {
	const { profile } = useSelector(state => state);
	const { changeRoute, disableRouting } = useSelector(state => state.application);
	const navigate = useNavigate();

	const [unsavedDialog, setUnsavedDialog] = useState(false);

	const dispatch = useDispatch();
	// TODO: move languages into a catalog
	const languages = ["FR", "DE", "EN", "ES", "IT", "PT", "ZH"];

	const TONALITY_LIST = [
		"cordial",
		"happily",
		"serious",
		"casual",
		"familiar",
		"formal",
		"polite",
		"professional"
	];
	// Check if settings are equal to copySetting to check if we have modified something
	const equalSetting = () => {
		let equal = lod_.isEqual(settings, copySetting);
		if (equal) {
			dispatch(setEnableRouting());
		} else {
			dispatch(setDisableRouting());
		}
		return equal;
	};

	useEffect(() => {
		if (!disableRouting || changeRoute === route.route) {
			return;
		}
		setUnsavedDialog(true);
	}, [changeRoute]);

	// Change value in the settings
	const onChange = (path, value) => {
		const newSettings = lod_.cloneDeep(settings);
		lod_.set(newSettings, path, value);
		setSettings(newSettings);
	};
	// Insert a new language line in object
	function insertNewLanguageLine(path) {
		let objLanguages = lod_.get(settings, path);
		objLanguages = Object.keys(objLanguages);
		let allLanguages = lod_.difference(languages, objLanguages);

		if (!allLanguages.length) {
			return;
		}
		let selectedLanguage = allLanguages[0];

		let newSettings = lod_.cloneDeep(settings);
		let settingsLanguage = lod_.get(newSettings, path);
		settingsLanguage[selectedLanguage] = "";
		lod_.set(newSettings, path, settingsLanguage);
		setSettings(newSettings);
	}
	// Remove a language line in object
	function removeLanguageRow(path, lang) {
		let newSettings = lod_.cloneDeep(settings);
		let settingsLanguage = lod_.get(newSettings, path);
		delete settingsLanguage[lang];
		lod_.set(newSettings, path, settingsLanguage);
		setSettings(newSettings);
	}
	// Save settings
	const saveSettings = () => {
		const onSuccess = res => {
			setSettings(res.config);
			setCopySetting(res.config);
		};

		let settingAttributesToSave = {
			apiKey: settings.apiKey,
			emptyContextReply: settings.emptyContextReply,
			iDontKnowReply: settings.iDontKnowReply,
			errorReply: settings.errorReply,
			inputs: settings.inputs,
			limits: settings.limits,
			models: settings.models
		};

		dispatch(FaiqActions.saveFaiqConfig(profile.assistantID, settingAttributesToSave, onSuccess));
	};

	return (
		<>
			{/* Unsaved dialog */}
			{unsavedDialog && (
				<Dialog
					fullWidth
					maxWidth="md"
					open={unsavedDialog}
					onClose={() => {
						setUnsavedDialog(false);
						dispatch(setEnableRouting());
					}}
				>
					<MDBox p={3}>
						<MDTypography variant="h4">{i18n.t("SETTINGS.dialogSave.title")}</MDTypography>
						<MDBox mt={2}>
							<MDTypography variant="body2">{i18n.t("SETTINGS.dialogSave.message")}</MDTypography>
						</MDBox>
						<MDBox mt={2} display="flex" justifyContent="space-between">
							<MDButton
								onClick={() => {
									setUnsavedDialog(false);
									// Reset route to actual one
									dispatch(changeRouteHandle(route.route));
								}}
							>
								{i18n.t("SETTINGS.cancel")}
							</MDButton>

							<MDBox display="flex">
								<MDBox mr={1}>
									<MDButton
										variant="gradient"
										color="error"
										onClick={() => {
											// Close dialog
											setUnsavedDialog(false);
											// Reenable routing
											dispatch(setEnableRouting());
											// Go to the new route (where user wanted to go) and dont save settings
											navigate(changeRoute);
										}}
									>
										<Icon>delete</Icon>&nbsp;{i18n.t("SETTINGS.dialogSave.dontSave")}
									</MDButton>
								</MDBox>

								<MDButton
									variant="gradient"
									color="info"
									onClick={() => {
										// Close dialog
										setUnsavedDialog(false);
										// Save settings
										saveSettings();
										// Reenable routing
										dispatch(setEnableRouting());
										// Go to the new route (where user wanted to go)
										navigate(changeRoute);
									}}
								>
									<Icon>save</Icon>&nbsp;{i18n.t("SETTINGS.dialogSave.save")}
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Dialog>
			)}

			<MDBox mt={3} mb={3}>
				<MDTypography variant="h3">{i18n.t("CONFIG.chatConfigTitle")}</MDTypography>
			</MDBox>

			<MDBox mt={3}>
				<Card>
					<CardContent>
						<MDBox>
							{/*
							 * Assistant role
							 */}
							<MDBox mt={2}>
								<MDTypography variant="h6">{i18n.t("CONFIG.role")}</MDTypography>
								<MDBox>
									<MDBox mt={1} display="flex" alignItems="center">
										<MDBox flex="1">
											<MDInput
												className="dialogInput"
												value={settings.inputs?.role || ""}
												placeholder={i18n.t("CONFIG.rolePlaceholder")}
												onChange={e => onChange(`inputs.role`, e.target.value)}
											></MDInput>
										</MDBox>
									</MDBox>
								</MDBox>
							</MDBox>
							{/*
							 * Organisation
							 */}
							<MDBox mt={2}>
								<MDTypography variant="h6">{i18n.t("CONFIG.organisation")}</MDTypography>
								<MDBox>
									<MDBox mt={1} display="flex" alignItems="center">
										<MDBox flex="1">
											<MDInput
												className="dialogInput"
												value={settings.inputs?.organisation || ""}
												placeholder={i18n.t("CONFIG.organisationPlaceholder")}
												onChange={e => onChange(`inputs.organisation`, e.target.value)}
											></MDInput>
										</MDBox>
									</MDBox>
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>

				<MDBox
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(3, 1fr)",
						gap: "10px"
					}}
					mt={3}
				>
					{/* Emojis Card */}
					<Card>
						<CardContent>
							<MDBox mt={2}>
								<MDTypography variant="h6">{i18n.t("CONFIG.emojis")}</MDTypography>
								<MDBox>
									<MDBox mt={1}>
										<Slider
											defaultValue={settings.inputs?.emojisCount || 1}
											marks={[
												{ value: 0, label: "0" },
												{ value: 1, label: "1" },
												{ value: 2, label: "2" },
												{ value: 3, label: "3" },
												{ value: 4, label: "4" },
												{ value: 5, label: "5" }
											]}
											min={0}
											max={5}
											onChange={(e, value) => {
												onChange(`inputs.emojisCount`, value);
											}}
										/>
									</MDBox>
								</MDBox>
							</MDBox>
						</CardContent>
					</Card>

					{/* Tonality Card */}
					<Card>
						<CardContent>
							<MDBox mt={2}>
								<MDTypography variant="h6">{i18n.t("CONFIG.answerTone")}</MDTypography>
								<MDBox>
									<MDBox mt={1}>
										<FormControl fullWidth>
											<InputLabel id="select-label">{i18n.t("CONFIG.answerTone")}</InputLabel>
											<Select
												labelId="select-label"
												id="select"
												label={i18n.t("CONFIG.answerTone")}
												value={settings.inputs?.answerTone || i18n.t(`CONFIG.tonalities.cordial`)}
												onChange={e => {
													onChange(`inputs.answerTone`, e.target.value);
												}}
											>
												{TONALITY_LIST.map((option, index) => (
													<MenuItem key={index} value={i18n.t(`CONFIG.tonalities.${option}`)}>
														{i18n.t(`CONFIG.tonalities.${option}`)}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</MDBox>
								</MDBox>
							</MDBox>
						</CardContent>
					</Card>

					{/* Form Card */}
					<Card>
						<CardContent>
							<MDBox mt={2}>
								<MDTypography variant="h6">{i18n.t("CONFIG.youForm")}</MDTypography>
								<MDBox>
									<MDBox mt={1}>
										<RadioGroup
											defaultValue={settings.inputs?.youForm || "vouvoiement"}
											name="radio-buttons-group"
											row
										>
											<FormControlLabel
												value="vouvoiement"
												control={
													<Radio
														onClick={() => {
															onChange(`inputs.youForm`, "vouvoiement");
														}}
													/>
												}
												label={i18n.t("CONFIG.vouvoiement")}
											/>
											<FormControlLabel
												value="tutoiement"
												control={
													<Radio
														onClick={() => {
															onChange(`inputs.youForm`, "tutoiement");
														}}
													/>
												}
												label={i18n.t("CONFIG.tutoiement")}
											/>
										</RadioGroup>
									</MDBox>
								</MDBox>
							</MDBox>
						</CardContent>
					</Card>

					{/* Maximum Words Card */}
					<Card>
						<CardContent>
							<MDBox mt={2}>
								<MDTypography variant="h6">{i18n.t("CONFIG.maximumWords")}</MDTypography>
								<MDBox>
									<MDBox mt={1}>
										<Slider
											defaultValue={settings.inputs?.maximumWords || 20}
											step={10}
											min={20}
											max={100}
											onChange={(e, value) => {
												onChange(`inputs.maximumWords`, value);
											}}
										/>
										<MDBox display="flex" justifyContent="space-between">
											<MDTypography variant="caption">{i18n.t("CONFIG.short")}</MDTypography>
											<MDTypography variant="caption">{i18n.t("CONFIG.long")}</MDTypography>
										</MDBox>
									</MDBox>
								</MDBox>
							</MDBox>
						</CardContent>
					</Card>

					{/* Temperature Card */}
					<Card>
						<CardContent>
							<MDBox mt={2}>
								<MDTypography variant="h6">{i18n.t("CONFIG.temperature")}</MDTypography>
								<MDBox>
									<MDBox mt={1}>
										<Slider
											value={settings.inputs?.temperature ?? 0.5}
											step={0.1}
											min={0}
											max={1.3}
											onChange={(e, value) => {
												onChange(`inputs.temperature`, value);
											}}
										/>
										<MDBox display="flex" justifyContent="space-between">
											<MDTypography variant="caption">
												{i18n.t("CONFIG.lowTemperature")}
											</MDTypography>
											<MDTypography variant="caption">
												{i18n.t("CONFIG.highTemperature")}
											</MDTypography>
										</MDBox>
									</MDBox>
								</MDBox>
							</MDBox>
						</CardContent>
					</Card>
				</MDBox>

				<Card sx={{ mt: 3 }}>
					<CardContent>
						<MDBox>
							{/* Empty context reply */}
							<MDBox mt={2}>
								<MDTypography variant="h6">{i18n.t("CONFIG.emptyContextReply")}</MDTypography>
								<MDBox>
									{Object.keys(settings.emptyContextReply).map((key, index) => {
										return (
											<MDBox key={index} mt={1} display="flex" alignItems="center">
												<MDBox mr={1}>
													<LanguageSelection
														path="emptyContextReply"
														index={key}
														languages={languages}
														settings={settings}
														setSettings={setSettings}
													/>
												</MDBox>

												<MDBox flex="1">
													<MDInput
														className="dialogInput"
														value={settings.emptyContextReply[key]}
														onChange={e => onChange(`emptyContextReply.${key}`, e.target.value)}
													></MDInput>
												</MDBox>
												<MDBox ml={1}>
													<IconButton
														onClick={() => removeLanguageRow("emptyContextReply", key)}
														disabled={Object.keys(settings.emptyContextReply).length < 2}
														variant="gradient"
														color="error"
													>
														<Icon>close</Icon>
													</IconButton>
												</MDBox>
											</MDBox>
										);
									})}
								</MDBox>
								{/* Add new reply */}
								<MDBox mt={1}>
									<MDButton
										onClick={() => {
											insertNewLanguageLine("emptyContextReply");
										}}
									>
										<Icon>add</Icon>&nbsp;{i18n.t("CONFIG.addResponseInLanguage")}
									</MDButton>
								</MDBox>
							</MDBox>
							{/*
							 * I don't know reply
							 */}
							<MDBox mt={4}>
								<MDTypography variant="h6">{i18n.t("CONFIG.iDontKnowReply")}</MDTypography>
								<MDBox>
									{Object.keys(settings.iDontKnowReply).map((key, index) => {
										return (
											<MDBox key={index} mt={1} display="flex" alignItems="center">
												<MDBox mr={1}>
													<LanguageSelection
														path="iDontKnowReply"
														index={key}
														languages={languages}
														settings={settings}
														setSettings={setSettings}
													/>
												</MDBox>

												<MDBox flex="1">
													<MDInput
														className="dialogInput"
														value={settings.iDontKnowReply[key]}
														onChange={e => onChange(`iDontKnowReply.${key}`, e.target.value)}
													></MDInput>
												</MDBox>
												<MDBox ml={1}>
													<IconButton
														onClick={() => removeLanguageRow("iDontKnowReply", key)}
														disabled={Object.keys(settings.iDontKnowReply).length < 2}
														variant="gradient"
														color="error"
													>
														<Icon>close</Icon>
													</IconButton>
												</MDBox>
											</MDBox>
										);
									})}
								</MDBox>
								{/* Add new reply */}
								<MDBox mt={1}>
									<MDButton
										onClick={() => {
											insertNewLanguageLine("iDontKnowReply");
										}}
									>
										<Icon>add</Icon>&nbsp;{i18n.t("CONFIG.addResponseInLanguage")}
									</MDButton>
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>

				<AdvancedSettings settings={settings} onChange={onChange} />
			</MDBox>
			{/* Floating button */}
			<Fade in={!equalSetting()}>
				<div
					style={{
						position: "fixed",
						bottom: 20,
						right: "50%",
						transform: "translateX(50%)",
						zIndex: 1000
					}}
				>
					<Fab variant="extended" color="info" onClick={saveSettings}>
						<MDTypography variant="h6" color="light">
							{i18n.t("SETTINGS.RULES.detectedModifications")}
						</MDTypography>
						<MDBox
							bgColor="light"
							p={1}
							borderRadius="lg"
							fontWeight="bold"
							display="flex"
							alignItems="center"
							ml={2}
						>
							<Save sx={{ mr: 1 }} />
							&nbsp;{i18n.t("SETTINGS.save")}
						</MDBox>
					</Fab>
				</div>
			</Fade>
		</>
	);
};

export default SettingsPart;
