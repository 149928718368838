/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import lod_ from "lodash";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import Step1Metadatas from "./processSteps/1. Metadatas";
import Step2Summary from "./processSteps/2. Summary";
import { socket } from "redux-react/middleware/ws";
import { useDispatch, useSelector } from "react-redux";
import Step3Extract from "./processSteps/3. Extract";
import { addNotification } from "redux-react/reducers/notificationsReducer";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import FaiqActions from "redux-react/actions/faiqActions";
import SourceActions from "redux-react/actions/sourceActions";
import FormAction from "redux-react/actions/formAction";
import { t } from "i18next";

export default function ProcessSourceDialog({
	open,
	handleCloseDialog,
	handleSave,
	source: sourceProps,
	metadatasDictionary
}) {
	const [source, setSource] = useState(null);
	const dispatch = useDispatch();
	const topContainer = useRef(null);

	const profile = useSelector(selectCurrentProfile);

	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);
	/**
	 * Steps labels
	 */
	const [steps] = useState([
		t("SOURCES.PIPELINE.metadatas"),
		t("SOURCES.PIPELINE.summary"),
		t("SOURCES.PIPELINE.knowledges")
	]);
	const isLastStep = activeStep === steps.length - 1;

	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);

	const [editMode, setEditMode] = useState(false);
	const [extractDialog, setExtractDialog] = useState(false);

	const [datas, setDatas] = useState({});

	// Close dialog
	function close() {
		handleCloseDialog();
	}
	// Save source
	async function submit() {
		dispatch(
			SourceActions.updateSource(
				source.code,
				{
					metadatas: datas.metadatas,
					summary: datas.summary,
					temporary: {},
					"file.name": datas.fileName,
					reviewed: true
				},
				() => {
					handleSave();
				}
			)
		);
		close();
	}

	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};
	/**
	 * Go to back step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		setActiveStep(activeStep + 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};
	/**
	 * Extract knowledges
	 */
	const extractKnowledgesOpenDialog = () => {
		setExtractDialog(true);
	};

	const extractKnowledges = () => {
		const onSuccess = res => {
			dispatch(
				addNotification({
					assistantID: profile.assistantID,
					type: "info",
					message: t("SOURCES.PIPELINE.CALLS.extractInProgress"),
					ts: new Date().getTime()
				})
			);
			setDatas(skeleton => {
				return { ...skeleton, knowledgesExtractionInProgress: true, extractionDisabled: true };
			});
		};
		dispatch(FaiqActions.startFAIQPipeline([source.code], ["knowledges"], onSuccess));
	};
	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return (
					<Step1Metadatas
						skeleton={datas}
						handleSave={metadatas => {
							setDatas(skeleton => {
								return { ...skeleton, metadatas: metadatas };
							});
						}}
						validStep={validStep}
						metadatas={metadatasDictionary}
						lockGenerateButton={() => {
							setDatas(skeleton => {
								return { ...skeleton, metadatasGenerationDisabled: true };
							});
						}}
						source={source}
					/>
				);
			case 1: {
				return (
					<Step2Summary
						validStep={validStep}
						skeleton={datas}
						onChange={summary => {
							setDatas(skeleton => {
								return { ...skeleton, summary: summary };
							});
						}}
						lockGenerateButton={() => {
							setDatas(skeleton => {
								return { ...skeleton, summaryGenerationDisabled: true };
							});
						}}
						source={source}
					/>
				);
			}
			case 2: {
				return (
					<Step3Extract
						validStep={validStep}
						source={source}
						skeleton={datas}
						extractKnowledges={extractKnowledgesOpenDialog}
						setEditMode={setEditMode}
					/>
				);
			}
			default:
				return null;
		}
	}

	const sourcePipelineResult = result => {
		const success = result.success;

		if (!success) {
			setDatas(skeleton => {
				return { ...skeleton, summaryGenerationDisabled: false };
			});
			return;
		}

		const steps = result.steps ?? [];
		const source = result.source;

		if (steps.includes("metadatas")) {
			const metadatas = source.metadatas;
			setDatas(skeleton => {
				return { ...skeleton, metadatas: metadatas, metadatasGenerationDisabled: false };
			});
			setSource(prev => {
				return { ...prev, metadatas: metadatas };
			});
		}

		if (steps.includes("summary")) {
			const summary = source.summary;
			setDatas(skeleton => {
				return { ...skeleton, summary: summary, summaryGenerationDisabled: false };
			});
			setSource(prev => {
				return { ...prev, summary: summary };
			});
		}

		if (steps.includes("knowledges")) {
			setDatas(skeleton => {
				return { ...skeleton, knowledgesExtractionInProgress: false, extractionDisabled: false };
			});
			setSource(prev => {
				return { ...prev, extracted: source.extracted };
			});
			validStep();
		}
	};

	useEffect(() => {
		socket.on("faiq_pipeline_result", sourcePipelineResult);

		return () => {
			socket.off("faiq_pipeline_result", sourcePipelineResult);
		};
	}, []);

	useEffect(() => {
		// Call to back
		if (!lod_.isNil(sourceProps) && !lod_.isNil(sourceProps.code)) {
			dispatch(
				FormAction.getItemsFromCollection(
					"faiqSource",
					{
						query: {
							code: sourceProps.code
						},
						projection: {
							summary: 1
						}
					},
					res => {
						if (res.items.length < 1) {
							return;
						}
						const sourceItem = res.items[0];

						setDatas({
							summaryGenerationDisabled: false,
							extractionDisabled: false,
							summary: sourceItem.summary || "",
							metadatas: {},
							fileName: lod_.get(sourceProps, "file.name")
						});

						setSource({
							...sourceProps,
							...sourceItem
						});
					}
				)
			);
		}
	}, [sourceProps]);

	if (lod_.isNil(source)) {
		return null;
	}

	return (
		<Dialog
			fullWidth
			open={open}
			onClose={() => close()}
			PaperProps={{
				sx: {
					height: "95%",
					width: "100%",
					maxWidth: "100%"
				}
			}}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent style={{ display: "flex" }}>
				<div data-id="top-container" ref={topContainer}></div>
				<MDBox mt={2} style={{ flex: 1, display: "flex" }}>
					{getStepContent(activeStep)}
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton disabled={editMode} variant="outlined" color="info" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				{activeStep > 0 && (
					<MDButton disabled={editMode} variant="contained" color="light" onClick={handleBack}>
						{t("SETTINGS.back")}
					</MDButton>
				)}
				{!source.extracted && isLastStep ? (
					<MDButton
						variant="contained"
						color="info"
						onClick={extractKnowledges}
						disabled={datas.knowledgesExtractionInProgress}
					>
						{t("SOURCES.PIPELINE.ACTIONS.extractKnowledges")}&nbsp;
						{datas.knowledgesExtractionInProgress ? (
							<CircularProgress size={16} color="white" />
						) : (
							<Icon>auto_awesome</Icon>
						)}
					</MDButton>
				) : (
					<MDButton
						disabled={editMode || !stepValid}
						variant="contained"
						color="info"
						onClick={!isLastStep ? handleNext : submit}
					>
						{!isLastStep ? i18n.t("SETTINGS.next") : i18n.t("SETTINGS.save")}
					</MDButton>
				)}
			</DialogActions>

			<Dialog open={extractDialog} onClose={() => setExtractDialog(false)}>
				<DialogTitle>{t("SOURCES.PIPELINE.ACTIONS.extractAlreadyTitle")}</DialogTitle>
				<DialogContent>{t("SOURCES.PIPELINE.ACTIONS.extractAlreadySubtitle")}</DialogContent>
				<DialogActions>
					<MDButton
						variant="outlined"
						color="info"
						onClick={() => {
							setExtractDialog(false);
						}}
					>
						{i18n.t("SETTINGS.cancel")}
					</MDButton>
					<MDButton
						variant="contained"
						color="info"
						onClick={() => {
							extractKnowledges();
							setExtractDialog(false);
						}}
					>
						{t("FORMS.validate")}
					</MDButton>
				</DialogActions>
			</Dialog>
		</Dialog>
	);
}
