/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.

// @mui material components
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { Icon, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "redux-react/reducers/notificationsReducer";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { lightBlue, orange, red, green, blue } from "@mui/material/colors";

import TimeAgo from "javascript-time-ago";
import fr from "javascript-time-ago/locale/fr";
import ReactTimeAgo from "react-time-ago";
// custom styles for the NotificationItem

const NotificationItem = forwardRef(({ icon, message, ts, id, type, viewed, ...rest }, ref) => {
	TimeAgo.addDefaultLocale(fr);
	TimeAgo.addLocale(fr);

	const dispatch = useDispatch();

	const profile = useSelector(selectCurrentProfile);

	const MAPPER = {
		info: lightBlue[500],
		warning: orange[500],
		error: red[500],
		success: green[500]
	};

	return (
		<MDBox {...rest} ref={ref} p={1}>
			<MDBox
				component={Link}
				py={0.5}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				{/* Icon */}
				<MDBox display="flex" alignItems="left">
					<Avatar sx={{ width: 30, height: 30, backgroundColor: MAPPER[type] }}>
						{icon ? (
							<Icon fontSize="small">{icon}</Icon>
						) : (
							<Icon fontSize="small">notifications</Icon>
						)}
					</Avatar>
				</MDBox>
				{/* Content */}
				<MDBox sx={{ ml: 1 }} flex="3" alignItems="left">
					<MDTypography variant="button" fontWeight="regular">
						{message}
					</MDTypography>
				</MDBox>
				{/* Time */}
				<MDBox>
					<MDTypography
						variant="button"
						fontWeight="normal"
						fontSize="small"
						style={{ opacity: 0.7 }}
					>
						<ReactTimeAgo date={ts} />
					</MDTypography>
				</MDBox>
				{/* Buttons */}
				<MDBox>
					<IconButton
						size="small"
						onClick={() => {
							dispatch(
								removeNotification({
									assistantID: profile.assistantID,
									id
								})
							);
						}}
					>
						<Icon>close</Icon>
					</IconButton>
				</MDBox>
			</MDBox>
		</MDBox>
	);
});

export default NotificationItem;
