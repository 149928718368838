import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";
import { t } from "i18next";

const actions = {
	deleteSources: (codes, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/source/many`,
					data: {
						sources: codes
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: t("SOURCES.DELETE.CALLS.error")
							})
						);
					}
				})
			);
		};
	},
	/* Update one source */
	updateSource: (code, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/source`,
					data: {
						code,
						update: data
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: t("SOURCES.UPDATE.CALLS.error")
							})
						);
					}
				})
			);
		};
	},
	/* Update many sources */
	updateSources: (codes, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/source/many`,
					data: {
						codes,
						update: data
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: t("SOURCES.UPDATE.CALLS.errorMany")
							})
						);
					}
				})
			);
		};
	},
	/* Get the channels integration */
	getIntegrationChannels: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/integration/channels`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Get sitemap */
	getIntegrationSitemap: (url, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/integration/sitemap`,
					data: {
						url
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Get URL */
	getIntegrationWebURL: (url, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/integration/url`,
					data: {
						url
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Check if sources exist in database */
	checkIfSourcesExist: (sources, onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/integration/exists`,
					data: {
						sources
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						onFailure(err);
					}
				})
			);
		};
	},
	/* Save sources */
	importSources: (data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/import`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: t("SOURCES.IMPORT.error")
							})
						);
					}
				})
			);
		};
	},
	/* Get shopify resource for channel */
	getShopifyResourceForChannel: (resource, assistantID, channelCode, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/source/integration/channels/shopify/${resource}/${assistantID}/${channelCode}`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: t("SOURCES.IMPORT.error")
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
