/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import { CircularProgress, Icon } from "@mui/material";
import MDInput from "components/Basics/MDInput";
import { useDispatch } from "react-redux";
import FaiqActions from "redux-react/actions/faiqActions";
import { t } from "i18next";

export default function Step2Summary({
	validStep,
	source,
	skeleton,
	onChange,
	lockGenerateButton
}) {
	const dispatch = useDispatch();

	const generateSummary = () => {
		lockGenerateButton();
		dispatch(FaiqActions.startFAIQPipeline([source.code], ["summary"], () => {}));
	};

	useEffect(() => {
		validStep();
	}, []);

	return (
		<MDBox
			sx={{ ml: 10, mr: 10 }}
			flex="1"
			display="flex"
			flexDirection="column"
			justifyContent="center"
		>
			<MDBox>
				<MDBox display="flex" alignItems="center" flexDirection="row" justifyContent="end">
					<MDBox alignItems="center">
						<MDButton
							color="info"
							onClick={generateSummary}
							disabled={skeleton.summaryGenerationDisabled}
						>
							{t("SOURCES.PIPELINE.ACTIONS.summaryGenerate")}&nbsp;
							{skeleton.summaryGenerationDisabled ? (
								<CircularProgress size={16} color="white" />
							) : (
								<Icon>auto_awesome</Icon>
							)}
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox
				mt={1}
				flex="1"
				display="flex"
				style={{
					overflowX: "hidden"
				}}
			>
				<MDInput
					multiline
					value={skeleton.summary}
					onChange={e => onChange(e.target.value)}
					placeholder={t("SOURCES.PIPELINE.ACTIONS.summaryTextareaPlaceholder")}
					fullWidth
					sx={{
						display: "flex",
						flex: 1,
						"& .MuiInputBase-root": {
							height: "100%",
							display: "flex",
							"& .MuiInputBase-input": {
								flex: 1,
								overflow: "auto",
								height: "100% !important",
								paddingBottom: "0 !important",
								paddingTop: "0 !important"
							}
						}
					}}
				/>
			</MDBox>
		</MDBox>
	);
}
