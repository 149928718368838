/* eslint-disable no-restricted-syntax */
import lod_ from "lodash";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	Tooltip
} from "@mui/material";

import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDBadge from "components/Basics/MDBadge";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";

import FormAction from "redux-react/actions/formAction";
import TestingAction from "redux-react/actions/testingActions";
import { display } from "redux-react/reducers/snackBarReducer";

/**
 * Dialog component to associate knowledges to a question
 * @param {*} param0 - Props
 * @param {string} param0.questionID - Question ID
 * @param {string} param0.threadID - Thread ID
 * @param {boolean} param0.open - Is the dialog open
 * @param {function} param0.onClose - Function to close the dialog
 * @returns {JSX.Element}
 */
const TestingQuestionDialog = ({ questionID, threadID, open, onClose }) => {
	const dispatch = useDispatch();

	const [question, setQuestion] = useState("");

	const [foundedKnowledges, setFoundedKnowledges] = useState([]);
	const [selectedKnowledges, setSelectedKnowledges] = useState([]);

	const [loading, setLoading] = useState(false);

	/**
	 * Get the knowledges content from their ids
	 * @param {Array<object>} knowledgeIds - Array of knowledge objects
	 */
	const getKnowledgesContent = knowledgeIds => {
		const IDs = knowledgeIds.map(k => k.uuid);
		dispatch(
			FormAction.getItemsFromCollection(
				"faiqKnowledge",
				{
					query: {
						uuid: { $in: IDs },
						active: { $in: [null, true] }
					},
					projection: {
						content: 1,
						uuid: 1
					}
				},
				res => {
					const knowledges = [];
					for (let knwoledge of res.items) {
						let partialKnowledge = knowledgeIds.find(k => k.uuid === knwoledge.uuid);
						knowledges.push({
							...knwoledge,
							...partialKnowledge
						});
					}
					setFoundedKnowledges(knowledges);
				}
			)
		);
	};

	/**
	 * Get the question history
	 */
	const getQuestionHistory = () => {
		dispatch(
			FormAction.getItemsFromCollection(
				"faiqHistory",
				{
					query: {
						questionID,
						threadID,
						active: { $in: [null, true] }
					},
					projection: {
						question: 1,
						knowledgeIds: 1
					}
				},
				res => {
					if (res.items.length === 0) {
						return;
					}

					const history = res.items[0];

					const knowledgeIds = history.knowledgeIds ?? [];

					setQuestion(history.question);

					getKnowledgesContent(knowledgeIds);
				}
			)
		);
	};

	/**
	 * Select a knowledge, add it to the selectedKnowledges array
	 * if it's not already in it, remove it otherwise
	 * @param {string} uuid - knowledge uuid
	 */
	const selectKnowledge = uuid => {
		if (selectedKnowledges.includes(uuid)) {
			setSelectedKnowledges(selectedKnowledges.filter(k => k !== uuid));
		} else {
			setSelectedKnowledges([...selectedKnowledges, uuid]);
		}
	};

	/**
	 * On input question change
	 * @param {object} e - event
	 */
	const onInputQuestionChange = e => {
		setQuestion(e.target.value);
	};

	/**
	 * Disable the save button
	 * @returns {boolean}
	 */
	const isDisabled = () => {
		if (lod_.isEmpty(selectedKnowledges)) {
			return true;
		}

		if (lod_.isEmpty(question?.trim()) || lod_.isNil(question?.trim())) {
			return true;
		}

		if (question.length < 5) {
			return true;
		}

		return false;
	};

	const createAssociation = async () => {
		setLoading(true);
		// 1- Create the question in database
		const { err: creationError } = await new Promise((resolve, reject) => {
			dispatch(
				TestingAction.createTestingQuestion(
					{
						question,
						questionID
					},
					res => resolve({ res }),
					err => resolve({ err })
				)
			);
		});

		if (creationError) {
			dispatch(display({ type: "error", message: t("FAIQ.TESTING.CALLS.errorCreateQuestion") }));
			setLoading(false);
			return;
		}

		// 2- Create association between the question and the knowledges
		const { err: associationError } = await new Promise(resolve => {
			dispatch(
				TestingAction.createTestingAssociation(
					questionID,
					selectedKnowledges,
					res => resolve({ res }),
					err => resolve({ err })
				)
			);
		});

		if (associationError) {
			dispatch(display({ type: "error", message: t("FAIQ.TESTING.CALLS.errorAssociateQuestion") }));
			setLoading(false);
			return;
		}

		// 3- Test
		const { err: testingError } = await new Promise(resolve => {
			dispatch(
				TestingAction.startTestingProcess(
					questionID,
					res => resolve({ res }),
					err => resolve({ err })
				)
			);
		});

		if (testingError) {
			dispatch(display({ type: "error", message: t("FAIQ.TESTING.CALLS.errorOvertraining") }));
			setLoading(false);
			return;
		}

		dispatch(display({ type: "success", message: t("FAIQ.TESTING.CALLS.successOvertraining") }));
		setLoading(false);
		onClose();
	};

	useEffect(() => {
		if (!open || lod_.isNil(questionID)) {
			return;
		}

		// Reset the state
		setSelectedKnowledges([]);
		setQuestion("");
		setLoading(false);

		// Get the question history
		getQuestionHistory();
	}, [questionID, open]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="xxl"
			PaperProps={{
				sx: {
					height: "90%"
				}
			}}
		>
			<DialogTitle id="alert-dialog-title">
				<MDBox>
					<MDTypography variant="h5">{t("FAIQ.TESTING.overtrainingTitle")}</MDTypography>
					<MDTypography variant="body2">{t("FAIQ.TESTING.overtrainingSubtitle")}</MDTypography>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<MDInput
					fullWidth
					label={t("FAIQ.TESTING.question")}
					sx={{ mt: 3 }}
					value={question}
					onChange={onInputQuestionChange}
				/>
				{foundedKnowledges.map((knowledge, index) => (
					<KnowledgeDisplay
						key={index}
						knowledge={knowledge}
						index={index}
						selectKnowledge={selectKnowledge}
						selectedKnowledges={selectedKnowledges}
					/>
				))}
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					{t("SETTINGS.close")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={createAssociation}
					disabled={isDisabled() || loading}
				>
					{t("SETTINGS.overtraining")}
					{loading && <CircularProgress sx={{ ml: 2 }} size={16} color="white" />}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default TestingQuestionDialog;

/**
 * KnowledgeDisplay component display a knowledge (content + distance)
 * @param {*} param0 - Props
 * @param {object} param0.knowledge - Knowledge object
 * @param {number} param0.index - Index
 * @param {function} param0.selectKnowledge - Function to select a knowledge
 * @param {Array<string>} param0.selectedKnowledges - Array of selected knowledges
 * @returns {JSX.Element}
 */
const KnowledgeDisplay = ({ knowledge, index, selectKnowledge, selectedKnowledges = [] }) => {
	return (
		<MDBox
			mt={2}
			borderRadius="md"
			style={{
				cursor: "pointer",
				overflow: "hidden"
			}}
			shadow="lg"
			onClick={e => {
				e.stopPropagation();
				selectKnowledge(knowledge.uuid);
			}}
		>
			<MDBox bgColor="white">
				{/* Header */}
				<MDBox
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					shadow="md"
					bgColor={selectedKnowledges.includes(knowledge.uuid) ? "info" : "light"}
					p={1}
				>
					<MDBox display="flex" alignItems="center">
						<Tooltip placement="top" title={`Connaissance n°${knowledge.uuid}`}>
							<MDBadge color="dark" badgeContent={`#${index + 1}`} container size="md" />
						</Tooltip>

						<MDBox display="flex" alignItems="center" ml={1}>
							<MDBadge
								color="success"
								badgeContent={`Score : ${knowledge.distance.toFixed(4)}`}
								container
								size="md"
							/>
						</MDBox>
					</MDBox>
					<MDBox>
						<Checkbox checked={selectedKnowledges.includes(knowledge.uuid)} />
					</MDBox>
				</MDBox>
				{/* Content */}
				<MDBox ml={1}>
					<MDTypography
						variant="body2"
						fontSize="small"
						dangerouslySetInnerHTML={{ __html: knowledge.content }}
						style={{
							whiteSpace: "pre-wrap",
							userSelect: "none",
							height: "30vh",
							overflowY: "auto"
						}}
					></MDTypography>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};
