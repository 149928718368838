import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import SiteMapImport from "./components/SiteMapImport";
import { IMPORT_METHODS } from "constants";
import LocalFileImport from "./components/LocalFileImport";
import ShopifySelection from "./components/Shopify";
import WebImport from "./components/WebImport";
import { t } from "i18next";

/**
 * Select files or convert files to correct format
 * @param {*} param0
 * @returns {JSX.Element} StepSelection
 */
export default function StepSelection({ skeleton, validStep, handleSave }) {
	if (lod_.isNil(skeleton.importMethod)) {
		return <MDBox>{t("SOURCES.notValidMethod")}</MDBox>;
	}

	// Save files
	const onFilesSelection = async files => {
		handleSave(files);
	};

	// All possible selection methods
	const getSelectionMethod = importMethod => {
		switch (importMethod) {
			case IMPORT_METHODS.LOCAL_FILE: {
				const files = Array.from(skeleton.localFiles);
				return (
					<LocalFileImport
						files={files}
						skeleton={skeleton}
						onFilesSelection={onFilesSelection}
						validStep={validStep}
					/>
				);
			}
			case IMPORT_METHODS.URL: {
				return <WebImport onFilesSelection={onFilesSelection} validStep={validStep} />;
			}
			case IMPORT_METHODS.SITEMAP: {
				return <SiteMapImport onFilesSelection={onFilesSelection} validStep={validStep} />;
			}
			case IMPORT_METHODS.SHOPIFY: {
				return (
					<ShopifySelection
						onFilesSelection={onFilesSelection}
						validStep={validStep}
						extra={skeleton.extra}
					/>
				);
			}
			default:
				return <MDBox>{t("SOURCES.notValidMethod")}</MDBox>;
		}
	};

	return (
		<MDBox flex="1" display="flex" flexDirection="row" style={{ height: "100%", width: "100%" }}>
			{getSelectionMethod(skeleton.importMethod)}
		</MDBox>
	);
}
