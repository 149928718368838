import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { Checkbox, Icon, IconButton, Tooltip } from "@mui/material";
import MDBadge from "components/Basics/MDBadge";
import TestingActions from "redux-react/actions/testingActions";
import { useDispatch } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";

export default function KnowledgeDisplay({
	searched = false,
	selectedQuestion,
	knowledge,
	index,
	selectedKnowledges = [],
	selectKnowledge,
	refreshPannel
}) {
	const dispatch = useDispatch();
	const isAlreadyAssociated = uuid => {
		return selectedQuestion.associations?.find(a => a?.knowledges?.includes(uuid));
	};

	const deleteAssociation = () => {
		const associationID = isAlreadyAssociated(knowledge.uuid)?.uuid;
		if (associationID) {
			dispatch(
				TestingActions.deleteTestingAssociation(
					selectedQuestion.uuid,
					isAlreadyAssociated(knowledge.uuid)?.uuid,
					knowledge.uuid,
					() => {
						refreshPannel();
					}
				)
			);
		} else {
			dispatch(display({ message: "L'association n'existe pas", type: "error" }));
		}
	};

	return (
		<MDBox
			mt={2}
			borderRadius="md"
			shadow="lg"
			style={{
				cursor: isAlreadyAssociated(knowledge.uuid) ? "inherit" : "pointer",
				userSelect: "none",
				overflow: "hidden"
			}}
			bgColor={selectedKnowledges.includes(knowledge.uuid) ? "light" : "transparent"}
			onClick={e => {
				e.stopPropagation();
				if (!isAlreadyAssociated(knowledge.uuid)) {
					selectKnowledge(knowledge.uuid);
				}
			}}
		>
			<MDBox
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				shadow="md"
				bgColor={selectedKnowledges.includes(knowledge.uuid) ? "info" : "light"}
				p={1}
			>
				<MDBox display="flex" alignItems="center">
					{!searched && (
						<Tooltip placement="top" title={`Connaissance n°${knowledge.uuid}`}>
							<MDBadge color="dark" badgeContent={`#${index + 1}`} container size="md" />
						</Tooltip>
					)}
					<Tooltip
						placement="top"
						title={
							knowledge.score < selectedQuestion.minimumDistance
								? "En dessous du seuil"
								: "Au dessus du seuil"
						}
					>
						{searched ? (
							<MDBox display="flex" alignItems="center">
								<MDBadge size="small" color="info" badgeContent="Recherche" container />
							</MDBox>
						) : (
							<MDBox display="flex" alignItems="center" ml={1}>
								<MDBadge
									color={knowledge.score < selectedQuestion.minimumDistance ? "error" : "success"}
									badgeContent={`Score : ${knowledge.score.toFixed(4)}`}
									container
									size="md"
								/>
							</MDBox>
						)}
					</Tooltip>
				</MDBox>
				{!isAlreadyAssociated(knowledge.uuid) ? (
					<MDBox>
						<Checkbox checked={selectedKnowledges.includes(knowledge.uuid)} />
					</MDBox>
				) : (
					<Tooltip placement="top" title={`Supprimer l'association`}>
						<MDBox m={1}>
							<IconButton onClick={deleteAssociation}>
								<Icon>link_off</Icon>
							</IconButton>
						</MDBox>
					</Tooltip>
				)}
			</MDBox>
			<MDBox ml={1}>
				<MDTypography
					variant="body2"
					fontSize="small"
					dangerouslySetInnerHTML={{ __html: knowledge.content }}
					style={{
						whiteSpace: "pre-wrap",
						userSelect: "none",
						height: "30vh",
						overflowY: "auto"
					}}
				></MDTypography>
			</MDBox>
		</MDBox>
	);
}
