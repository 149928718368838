/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import { IMPORT_METHODS } from "constants";
import { uploadToSignedUrl, getSignedUrl } from "helpers/s3";
import SourceActions from "redux-react/actions/sourceActions";

/**
 * Upload file to S3
 * @param {File} file - File to upload
 * @returns {Promise} - Promise object represents the result of the upload
 */
async function uploadFileToS3(file) {
	const { success, error, result } = await await getSignedUrl(file);

	if (!success) {
		return {
			success: false,
			error
		};
	}

	const signedUrl = result.signedUrl;
	const fileObject = result.fileObject;

	await uploadToSignedUrl(signedUrl, file);

	return {
		success: true,
		result: {
			signedUrl,
			fileObject
		}
	};
}

/**
 * Check if sources exist in database
 * @param {Array<object>} sources - Array of sources
 * @returns {Promise} - Promise object represents the result of the check
 */
export const checkIfSourcesExist = async (dispatch, sources) => {
	return new Promise((resolve, reject) => {
		dispatch(
			SourceActions.checkIfSourcesExist(
				sources,
				res => {
					resolve(res);
				},
				err => {
					reject(err);
				}
			)
		);
	});
};

/**
 * Import files into fAIbrik sources
 * @param {Function} dispatch - Redux dispatch function
 * @param {object} skeleton - Import skeleton
 */
export const importSources = async (dispatch, skeleton) => {
	const { importMethod, localFiles, filesToProcess, metadatas } = skeleton;
	const files = Array.from(localFiles);

	const sources = [];

	for (let source of filesToProcess) {
		// { code, url, alreadyExists, image?, extra? } = source

		let file = {
			alreadyExists: source.alreadyExists,
			code: source.url,
			type: null,
			metadatas: {
				// Custom metadatas
				...metadatas,
				// Default metadatas (override custom metadatas)
				title: source.name,
				description: null,
				image: source.image,
				url: source.url,
				source: source.url
			}
		};

		switch (importMethod) {
			case IMPORT_METHODS.SHOPIFY: {
				file.type = "html";
				file.file = {
					name: source.name,
					contentType: "html",
					url: source.url,
					provider: "external"
				};
				file.extra = {
					apiResult: source.extra?.apiResult,
					rawText: source.extra?.rawText
				};
				break;
			}
			case IMPORT_METHODS.LOCAL_FILE: {
				// Need to upload file to S3
				const localFile = files.find(file => file.name === source.url);
				const { success, error, result } = await uploadFileToS3(localFile);
				if (success) {
					file.file = result.fileObject;
					file.metadatas.url = result.fileObject.url;
				} else {
					file.file = {};
				}
				break;
			}
			default: {
				file.file = {
					name: source.name,
					contentType: null,
					url: source.url,
					provider: "external"
				};
				break;
			}
		}

		sources.push(file);
	}

	// Import sources in backend
	return new Promise(resolve => {
		dispatch(
			SourceActions.importSources(
				{
					importMethod,
					sources
				},
				res => {
					resolve(res);
				},
				err => {
					resolve(err);
				}
			)
		);
	});
};
