/* eslint-disable object-shorthand */
/* eslint-disable no-restricted-syntax */
/**
 * Main application knowledge
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import MDTypography from "components/Basics/MDTypography";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Icon,
	IconButton,
	Menu,
	MenuItem
} from "@mui/material";
import i18n from "i18n";
import lod_ from "lodash";
import { read, utils } from "xlsx";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import TestingActions from "redux-react/actions/testingActions";
import { display } from "redux-react/reducers/snackBarReducer";
import FormDictionaryDialog from "components/Custom/FormDictionary";
import RightPannel from "./components/RightPannel";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";

const ImportResults = ({ open, questions, handleClose }) => {
	const dispatch = useDispatch();

	const saveQuestions = () => {
		const onSuccess = res => {
			handleClose(true);
			dispatch(
				display({
					type: "success",
					message: `${questions.length} questions ont été ajoutées avec succès`
				})
			);
		};

		dispatch(TestingActions.createManyTestingQuestion(questions, onSuccess));
	};

	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={() => handleClose()}>
			<DialogTitle>{`${questions.length}`} Questions trouvées</DialogTitle>
			<DialogContent>
				{questions.map((question, index) => {
					return (
						<MDBox key={index} mt={1}>
							<MDTypography variant="body2" fontSize="medium">
								{question}
							</MDTypography>
						</MDBox>
					);
				})}
			</DialogContent>
			<DialogActions>
				<MDButton variant="contained" onClick={handleClose}>
					{i18n.t("SETTINGS.close")}
				</MDButton>
				<MDButton variant="contained" color="info" onClick={saveQuestions}>
					{i18n.t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

/**
 * Main component
 */
export default function TestingPage({ route }) {
	// Form
	const [addNewItem, setAddNewItem] = useState(false);
	const [valuesForm, setValuesForm] = useState({});
	const [formBuild, setFormBuild] = useState({});
	const [contextDictionary, setContextDictionary] = useState({});
	const [PI, setPI] = useState(false);
	const [empty, setEmpty] = useState(false);

	const [selectedTarget, setSelectedTarget] = useState("");
	const [routeDict, setRouteDict] = useState("");
	const [selectedId, setSelectedId] = useState(null);

	// Page
	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();
	// Main chart
	const [rawCharts, setRawCharts] = useState({});
	const [pageFilters, setPageFilters] = useState([]);
	const [paginedList, setPaginedList] = useState(null);

	const [reloadTable, setReloadTable] = useState(false);

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});

	const [openPannel, setOpenPannel] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const rightPannelContainer = useRef(null);

	// Import results dialog
	const [importResults, setImportResults] = useState({
		open: false,
		questions: []
	});

	/* Load chart */
	function loadPaginedList() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts(res.charts);
			setReloadTable(!reloadTable);
		};

		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));
	}
	/* Build charts */
	function buildRawCharts() {
		if (lod_.isEmpty(rawCharts)) return;
		// Success handler : set charts data
		const onSuccess = res => {
			setPaginedList(res.charts[0]);
		};
		// Build charts
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		dispatch(ChartsActions.buildRawCharts(rawCharts, filters, mandatoryFilters, onSuccess));
	}

	const handleImportFile = event => {
		try {
			let file = event.target.files[0];
			const reader = new FileReader();
			// 1- Need to read the file
			reader.onload = e => {
				const wb = read(e.target.result);
				const sheets = wb.SheetNames;
				const data = utils.sheet_to_json(wb.Sheets[sheets[0]]);

				let questions = [];

				for (let row of data) {
					let values = Object.values(row);
					questions = [...questions, ...values];
				}

				setImportResults({
					open: true,
					questions: questions
				});
			};
			reader.readAsArrayBuffer(file);

			event.target.value = null;
		} catch (error) {
			//
		}
	};

	const testFAIQ = async uuid => {
		dispatch(
			display({
				message: i18n.t(`FAIQ.TESTING.waitingMessageTesting`),
				type: "success"
			})
		);

		if (lod_.isNil(uuid)) {
			await new Promise(resolve => {
				dispatch(
					TestingActions.startTestingProcessForAll(
						res => resolve({ res }),
						err => resolve({ err })
					)
				);
			});
		} else {
			let { res, err } = await new Promise(resolve => {
				dispatch(
					TestingActions.startTestingProcess(
						uuid,
						res => resolve({ res }),
						err => resolve({ err })
					)
				);
			});

			setReloadTable(!reloadTable);
			if (!lod_.isEmpty(res)) {
				setSelectedQuestion(res);
				setOpenPannel(true);
			}
		}

		dispatch(
			display({
				message: i18n.t(`FAIQ.TESTING.resultMessageTesting`),
				type: "success"
			})
		);
	};

	const closePannel = () => {
		setOpenPannel(false);
		setSelectedQuestion(null);
	};

	const actionEditEmptyHandle = () => {
		const onSuccess = res => {
			setAddNewItem(true);
			setEmpty(true);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(true);
			setSelectedTarget(route.form.collectionDefault);
			setRouteDict(route.form.routeDictionary);
			setReloadTable(!reloadTable);
		};

		dispatch(
			FormActions.getItemEmpty(route.form.collectionDefault, route.form.routeDictionary, onSuccess)
		);
	};

	/**
	 * Delete question
	 * @param {string} questionID - Question ID
	 */
	const deleteQuestion = questionID => {
		dispatch(
			TestingActions.deleteTestingQuestion([questionID], () => {
				dispatch(
					display({
						message: i18n.t("FORMS.deleteSuccess"),
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
			})
		);
	};

	const actionSaveOrCreateItem = async ({ question }) => {
		if (!lod_.isNil(question?.trim()) && !lod_.isEmpty(question?.trim())) {
			const { err: creationError } = await new Promise((resolve, reject) => {
				dispatch(
					TestingActions.createTestingQuestion(
						{
							question
						},
						res => resolve({ res }),
						err => resolve({ err })
					)
				);
			});

			if (creationError) {
				dispatch(display({ type: "error", message: "Erreur lors de la création de la question" }));
				return;
			}

			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setAddNewItem(false);
		}
	};

	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	return (
		<DashboardLayout>
			<MDBox>
				<DashboardNavbar
					filters={[
						<MDBox
							display="flex"
							justifyContent="space-between"
							style={{
								width: "100%"
							}}
						>
							{/* First container */}
							<MDBox display="flex">
								{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
								<MDButton
									style={{ height: "100%", marginRight: "0.75rem" }}
									variant="contained"
									color="info"
									onClick={actionEditEmptyHandle}
								>
									<Icon>add</Icon>&nbsp;
									{i18n.t("SETTINGS.add")}
								</MDButton>
								<MDButton
									style={{ height: "100%" }}
									variant="contained"
									component="label"
									color="info"
								>
									<input
										type="file"
										name="file"
										accept={[
											".xls",
											".xlsx",
											".xlsm",
											".xlsb",
											".xlt",
											".xltx",
											".xltm",
											".xlam",
											".ods",
											".csv"
										]}
										hidden
										onChange={handleImportFile}
									></input>
									<Icon>publish</Icon>&nbsp;{i18n.t("SETTINGS.import")}
								</MDButton>
							</MDBox>
							{/* Second container */}
							<MDBox display="flex" flexDirection="column">
								<MDButton
									variant="contained"
									color="error"
									onClick={() => {
										testFAIQ();
									}}
								>
									<Icon>adjust</Icon>&nbsp;{i18n.t("SETTINGS.test")}
								</MDButton>
							</MDBox>
						</MDBox>
					]}
				/>
			</MDBox>
			{paginedList && (
				<MDBox
					className="pageContentContainer"
					style={{
						display: openPannel ? "flex" : "block"
					}}
				>
					<MDBox
						flex="1"
						style={{
							overflowX: "auto"
						}}
					>
						<DefaultDataTable
							route={route}
							onRowClick={row => {
								if (!row.tested) {
									return;
								}

								setSelectedQuestion(row);
								setOpenPannel(true);
							}}
							isSelectedRow={values => {
								if (!selectedQuestion) {
									return false;
								}

								return values.uuid === selectedQuestion?.uuid;
							}}
							dictionary={paginedList.dictionary}
							canSearch
							table={paginedList.data}
							display={paginedList.request.attributesDisplay}
							pagination={paginedList.pagination}
							list={paginedList}
							actions={[
								<IconButton
									handleclick={({ values }, e) => {
										setMenuValues(values);
										setMenuRef(e.currentTarget);
										e.stopPropagation();
									}}
								>
									<Icon fontSize="medium">more_vert</Icon>
								</IconButton>
							]}
							reloadTable={reloadTable}
							filters={getLocalStorageBackValues(
								profile.selectedAssistant.assistantID,
								route.route,
								filters
							)}
						/>
					</MDBox>

					{openPannel && selectedQuestion && (
						<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
							<RightPannel
								questionCode={selectedQuestion.code ?? selectedQuestion.uuid}
								selectedQuestion={selectedQuestion}
								closePannel={closePannel}
								setSelectedQuestion={setSelectedQuestion}
								reloadTable={reloadTable}
							/>
						</MDBox>
					)}
				</MDBox>
			)}
			{/* Menu actions selection */}
			<Menu
				open={Boolean(menuRef)}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
				}}
			>
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.actions`)}</MDTypography>
				</MenuItem>
				{/* Test */}
				<MenuItem
					onClick={() => {
						if (!menuValues.uuid) return;
						testFAIQ(menuValues.uuid);
						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">adjust</Icon>&nbsp;{i18n.t(`FAIQ.TESTING.testing`)}
				</MenuItem>
				{/* Show knowledges */}
				<MenuItem
					disabled={!menuValues.tested}
					onClick={() => {
						setSelectedQuestion(menuValues);
						setOpenPannel(true);
						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">visibility</Icon>&nbsp;{i18n.t(`FAIQ.TESTING.knowledges`)}
				</MenuItem>
				{/* Delete */}
				<Divider />
				<ConfirmDialogButton
					onConfirm={(e, values) => {
						if (values?.associations?.length > 0) {
							dispatch(
								display({
									message: "Veuillez supprimer les assiocations avant de supprimer la question",
									type: "warning"
								})
							);
							setMenuRef(null);
						} else {
							deleteQuestion(values.code ?? values.uuid);
							setMenuRef(null);
						}
					}}
					component={
						<MenuItem style={{ color: "red" }}>
							<Icon color="error" fontSize="medium">
								delete
							</Icon>
							&nbsp;{i18n.t("SETTINGS.delete")}
						</MenuItem>
					}
					rowValues={menuValues}
					displayAttribute="question"
				/>
			</Menu>
			{/* Import dialog */}
			<ImportResults
				open={importResults.open}
				questions={importResults.questions}
				handleClose={(reload = false) => {
					setImportResults({
						open: false,
						questions: []
					});

					if (reload) {
						setReloadTable(!reloadTable);
					}
				}}
			/>
			{/* Form */}
			{addNewItem && (
				<FormDictionaryDialog
					open={addNewItem}
					route={route}
					handleCloseDialog={() => setAddNewItem(false)}
					handleSave={(values, unique, callback, rolesProfileUser) =>
						actionSaveOrCreateItem(values, unique, callback, rolesProfileUser)
					}
					valuesDictionary={valuesForm}
					formBuildDictionary={formBuild}
					contextDictionary={contextDictionary}
					PIaccess={PI}
					isEmpty={empty}
					target={selectedTarget}
					selectedId={selectedId}
					routeDict={routeDict}
				/>
			)}
			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
