/* eslint-disable import/prefer-default-export */
export const IMPORT_METHODS = {
	URL: "url",
	SITEMAP: "sitemap",
	LOCAL_FILE: "localFile",
	SHOPIFY: "shopify"
};

export const AVAILABLES_CHANNELS = [
	{
		type: "SHFY",
		importMethod: IMPORT_METHODS.SHOPIFY
	}
];

export const SHOPIFY_RESOURCE = {
	PAGES: "pages",
	PRODUCTS: "products"
};

export const CHAT_MODAL_MODES = {
	ASSISTANT: "assistant",
	HELP: "help"
};
