import { CircularProgress, Icon } from "@mui/material";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkIfSourcesExist } from "hooks/sources";
import { t } from "i18next";

/**
 * Import local files
 * No user actions needed, check only
 * if files exists in DB
 * @param {*} param0
 * @returns {JSX.Element} LocalFileImport
 */
const LocalFileImport = ({ files, skeleton, onFilesSelection, validStep }) => {
	const dispatch = useDispatch();

	const [filesLoaded, setFilesLoaded] = useState(false);

	const loadFiles = async () => {
		setFilesLoaded(false);

		if (lod_.isEmpty(skeleton.files)) {
			const mappedFiles = files.map(file => {
				const cleanedFileName = file.name.split(".")[0];
				return {
					name: cleanedFileName,
					url: file.name // For local files, the url is the file name
				};
			});

			const filesResult = await checkIfSourcesExist(dispatch, mappedFiles);

			setFilesLoaded(true);
			validStep();
			onFilesSelection(filesResult);
		} else {
			setFilesLoaded(true);
			validStep();
		}
	};

	// Load files on mount
	useEffect(() => {
		loadFiles();
	}, [files]);

	return (
		<MDBox mt={5} display="flex" flex="1" flexDirection="row" justifyContent="center">
			{filesLoaded ? (
				<MDBox sx={{ width: "70%", textAlign: "center" }}>
					<MDTypography variant="h4">{t("SOURCES.FILES.filesLoaded")}</MDTypography>
					<MDBox display="flex" justifyContent="center" mt={15}>
						<Icon sx={{ fontSize: "100px !important" }} color="success">
							check
						</Icon>
					</MDBox>
				</MDBox>
			) : (
				<MDBox sx={{ width: "70%", textAlign: "center" }}>
					<MDTypography variant="h4">{t("SOURCES.FILES.loadingFiles")}</MDTypography>
					<MDTypography variant="subtitle2">{t("SOURCES.FILES.waitWhileLoading")}</MDTypography>
					<MDBox display="flex" justifyContent="center" mt={15}>
						<CircularProgress color="info" size={50} />
					</MDBox>
				</MDBox>
			)}
		</MDBox>
	);
};

export default LocalFileImport;
